<header class="header-area">
  <div class="container">
    <div class="row">
      <div class="menu-area">
        <div class="hidden-xs hidden-sm">
          <div class="main-menu">
            <nav class="nav-menu" id="mainMenu">
              <ul>
                <li><a href="/home">Home</a></li>
                <li><a href="/appsInfo">Apps</a></li>
                <li><a href="/disclaimer">Disclaimer</a></li>
                <li><a href="/help">Help</a></li>
                <li><a href="/links">Links</a></li>
                <!--<li><a routerLink="/home" fragment="appLinks">More</a></li>-->
                <li><a href="/videos">Videos</a></li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-sm-10 col-xs-10 visible-sm visible-xs" style="margin-bottom: -5px">
          <div class="main-menu-mobile">
              <nav class="nav-menu" id="mainMenu2">
                <ul>
                  <li><a href="/home">Home</a></li>
                  <li><a href="/appsInfo">Apps</a></li>
                  <li><a href="/disclaimer">Disclaimer</a></li>
                  <li><a href="/help">Help</a></li>
                  <li><a href="/links">Links</a></li>
                  <li><a href="/videos">Videos</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
</header>
