<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<section class="pricing-area ptb--100" id="playlists">
  <div class="centreDiv hidden-xs hidden-sm">
  <mat-expansion-panel style="overflow: auto">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        HIGHLIGHT VIDEOS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.highlightVideos" [videosTitle]="'Highlight Videos'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        DOCUMENTARIES
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.documentaryVideos" [videosTitle]="'Documentaries'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        MMA FIGHTERS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.fightersVideos" [videosTitle]="'MMA Fighters'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto" >
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        MARTIAL ARTS PLAYLISTS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.playlistVideos" [videosTitle]="'Martial Arts Playlists'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        MMA TEAMS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.mmaTeamsVideos" [videosTitle]="'MMA Teams'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        VIDEO SERIES
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.tvSeriesVideos" [videosTitle]="'Video Series'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>

  <mat-expansion-panel style="overflow: auto;">
    <mat-expansion-panel-header>
      <mat-panel-title class="accordionText">
        FILM PRODUCERS
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-videos-list [videos]="videosPlaylists?.filmProducersVideos" [videosTitle]="'Film Producers'"
                     [videosPlaylists]="videos"></app-videos-list>
  </mat-expansion-panel>
  </div>
  <div class="visible-sm visible-xs">
    <br/>
    <br/>
    <app-videos-list [videos]="videosPlaylists?.highlightVideos" [videosTitle]="'Highlights'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.documentaryVideos" [videosTitle]="'Documentaries'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.fightersVideos" [videosTitle]="'Fighters'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.playlistVideos" [videosTitle]="'Martial Arts Playlists'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.mmaTeamsVideos" [videosTitle]="'MMA Teams'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.tvSeriesVideos" [videosTitle]="'Video Series'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>
    <app-videos-list [videos]="videosPlaylists?.filmProducersVideos" [videosTitle]="'Film Producers'"
                     [videosPlaylists]="videos"></app-videos-list>
    <hr/>

  </div>


</section>
<app-footer></app-footer>
