import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ImageService} from '../../service/image.service';

@Component({
  selector: 'app-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.css']
})
export class VideosListComponent implements OnInit {


  constructor(private imageService: ImageService) { }

  @Input() videosTitle: string;

  @Input() videos: any;

  @Input() videosPlaylists: any;

  @ViewChild('img', { static: true }) image: ElementRef;
  @ViewChild('newImg', { static: true }) newImage: ElementRef;


  ngOnInit(): void {
  }

  getImage(url: string) {
    const imageUrl = `https://thisismma.herokuapp.com/playlists/${url}.jpeg`;
    this.imageService.cacheUrls = [imageUrl];
      // @ts-ignore
    this.imageService.getImage(imageUrl).subscribe(res => {
      console.log(res);
      return this.image.nativeElement.src = imageUrl;
    });
  }

  getNumberOfVideos(categoryOne: string, categoryTwo: string) {
      let links = this.videosPlaylists?.[categoryOne]?.length;

      if (categoryTwo) {
        links += this.videosPlaylists?.[categoryTwo]?.length;
      }
      return links;
    }

}
