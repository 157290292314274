import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VideosComponent} from './videos/videos.component';
import {LinksComponent} from './links/links.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import { HomeComponent } from './home/home.component';
import { VideoPlaylistComponent } from './video-playlist/video-playlist.component';
import { VideoPlaylistTabsComponent } from './video-playlist-tabs/video-playlist-tabs.component';
import { AppsInfoComponent } from './apps-info/apps-info.component';
import { HelpComponent } from './help/help.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'videos',
    component: VideosComponent
  },
  {
    path: 'videoPlaylist',
    component: VideoPlaylistComponent
  },
  {
    path: 'videoPlaylistTabs',
    component: VideoPlaylistTabsComponent
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent
  },
  {
    path: 'appsInfo',
    component: AppsInfoComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'links',
    component: LinksComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
