import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';
import {ThisIsMMAService} from '../service/thisismma.service';
import {DomSanitizer} from '@angular/platform-browser';
import {merge, of} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import { videos } from '../videos/videos.js';

@Component({
  selector: 'app-video-playlist-tabs',
  templateUrl: './video-playlist-tabs.component.html',
  styleUrls: ['./video-playlist-tabs.component.css']
})
export class VideoPlaylistTabsComponent implements OnInit {

  videosTitle: string;
  dataSize: number;
  highlights: any;
  dataSource: any[];

  dataSizeCatTwo: number;
  documentaries: any;
  dataSourceCatTwo: any[];

  @ViewChild('matPaginator1', { static: true }) paginator: MatPaginator;

  @ViewChild('matPaginator2', { static: true }) paginatorCategoryTwo: MatPaginator;

  constructor(private route: ActivatedRoute,
              private thisIsMMAService: ThisIsMMAService,
              private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const {category, videosTitle, categoryTwo} = params;

      this.videosTitle = videosTitle;
      let highlights = [];

      highlights = videos?.[category];
      if (categoryTwo) {
        this.setLinksForCategoryOne(highlights);
        this.setLinksForCategoryTwo(videos?.[categoryTwo]);
      } else {
        this.setLinksForCategoryOne(highlights.filter(link => !link.doc));
        this.setLinksForCategoryTwo(highlights.filter(link => link.doc));
      }


      /*this.thisIsMMAService.getLinksByCategory(category).subscribe(data => {
        highlights = data;
        if (categoryTwo) {
          this.setLinksForCategoryOne(highlights);
          this.thisIsMMAService.getLinksByCategory(categoryTwo).subscribe(response => {
            this.setLinksForCategoryTwo(response);
          });
        } else {
          this.setLinksForCategoryOne(highlights.filter(link => !link.doc));
          this.setLinksForCategoryTwo(highlights.filter(link => link.doc));
        }
      });*/


    });
  }

  // this method will link data to paginator
  linkListToPaginator() {
    // merge simply joins all this operators and creates an       //observable that listen to paginator page events
    merge(this.paginator.page).pipe(
      startWith({}),
      switchMap(() => {
        // creates an observable of sample data
        return of(this.highlights);
      }))
      .subscribe(res => {
        const from = this.paginator.pageIndex * 10;
        const to = from + 10;
        this.dataSource = res.slice(from, to);
      });
  }

  setLinksForCategoryOne(links) {
      this.highlights = links;
      this.linkListToPaginator();
      this.dataSize = this.highlights?.length;
  }

  setLinksForCategoryTwo(links) {
      this.documentaries = links;
      this.linkListToPaginatorCategoryTwo();
      this.dataSizeCatTwo = this.documentaries?.length;
  }

  // this method will link data to paginator
  linkListToPaginatorCategoryTwo() {
    // merge simply joins all this operators and creates an       //observable that listen to paginator page events
    merge(this.paginatorCategoryTwo.page).pipe(
      startWith({}),
      switchMap(() => {
        // creates an observable of sample data
        return of(this.documentaries);
      }))
      .subscribe(res => {
        const from = this.paginatorCategoryTwo.pageIndex * 10;
        const to = from + 10;
        this.dataSourceCatTwo = res.slice(from, to);
      });
  }


  getVideoHref(imageLink: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageLink);
  }

  getLinkName(linkName: string) {
    const name = linkName?.length < 30 ? linkName || '' : linkName.substring(0, 30) + '...';
    return name;
  }
}
