<section class="slider-area" id="home">
  <app-header></app-header>
  <div class="container" id="headline1">
    <div class="slider-content d-flex flex-center">
      <div class="col-md-8 col-sm-9 col-xs-12 d-flex flex-center" style="padding: 0px">
        <div class="slider-inner hidden-sm hidden-xs">
          <h2>This is MMA: bringing the MMA world to you!</h2>
          <br/>
          <div class="btn-area">
            <a href="/videos" title="This is MMA videos"><i
              class="fa fa-camera"></i> Videos</a>&nbsp;
            <a href="/links" title="This is MMA YouTube links"> <i class="fa fa-play"></i> Links</a>&nbsp;
            <a href="/appsInfo" title="This is MMA apps"><i
              class="fa fa-apple"></i> Apps</a>
          </div>
        </div>
        <div class="slider-inner visible-xs visible-sm">
          <h2>This is MMA: bringing the MMA world to you!</h2>
          <br/>
          <div class="btn-area">
            <a href="/videos" title="This is MMA videos"><i
              class="fa fa-camera"></i> Videos</a><br/><br/>
            <a href="/links" title="This is MMA YouTube links"> <i class="fa fa-play"></i> Links</a><br/><br/>
            <a href="/appsInfo" title="This is MMA apps"><i
              class="fa fa-apple"></i> Apps</a>
          </div>
        </div>
      </div>
      <div class="col-md-4 hidden-sm hidden-xs">
        <div class="slider-m-img">
          <img src="assets/img/gsp1.png" alt="mobile image"/>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="about-area ptb&#45;&#45;110" id="about">
  <div class="container">
    <div class="row">
      <div class="col-md-5 hidden-sm hidden-xs">
        <div class="about-left-thumb">
          <img src="assets/img/videos1.png" alt="mobile screen"/>
        </div>
      </div>
      <div class="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
        <div class="about-content">
          <h2>Bringing the MMA world to you!</h2>
          <p><i>This is MMA</i> provides a comprehensive MMA video library containing links to over four thousand MMA
            /Martial Arts videos from the best of Dailymotion, Vimeo, and YouTube.
          </p>
          <div class="about-ft">
            <div class="ft-single">
              <div class="icon"><i class="fa fa-list"></i></div>
              <div class="ft-info">
                <h4>MMA Teams</h4>
                <p>Video playlists dedicated to the world's leading MMA teams</p>
              </div>
            </div>
            <div class="ft-single">
              <div class="icon"><i class="fa fa-file-video-o"></i></div>
              <div class="ft-info">
                <h4>Martial Arts Filmmakers</h4>
                <p>Five video playlists from leading Martial Arts filmmakers</p>
              </div>
            </div>
            <div class="ft-single">
              <div class="icon"><i class="fa fa-video-camera"></i></div>
              <div class="ft-info">
                <h4>125 Playlists</h4>
                <p>Video playlists including MMA organisations, martial arts legends, pioneers and highlights</p>
              </div>
            </div>
            <div class="ft-single">
              <div class="icon"><i class="fa fa-star"></i></div>
              <div class="ft-info">
                <h4>Recommended</h4>
                <p>Fifty of the best MMA/Martial Arts documentaries and highlight videos for you to enjoy</p>
              </div>
            </div>
          </div>
          <div class="ft-single">
            <div class="icon"><i class="fa fa-play-circle"></i></div>
            <div class="ft-info">
              <h4>Video Series</h4>
              <p>Forty of the most inspirational video series on the Martial Arts and MMA</p>
            </div>
          </div>
          <div class="ft-single">
            <div class="icon"><i class="fa fa-male"></i></div>
            <div class="ft-info">
              <h4>MMA Fighters</h4>
              <p>Video highlights and documentaries from leading MMA fighters</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="achivement-area ptb&#45;&#45;120 bg-theme
    text-center">
  <section id="summary3">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-video-camera"></i></div>
            <h2>4000+</h2>
            <span>Video Links</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-newspaper-o"></i></div>
            <h2>30</h2>
            <span>MMA Teams</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-youtube-play"></i></div>
            <h2>75</h2>
            <span>YouTube Channels</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-soundcloud"></i></div>
            <h2>20</h2>
            <span>MMA Podcasts</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<section class="pricing-area ptb--100" id="playlists">
  <div class="container visible-sm visible-xs">
    <div class="section-title text-black">
      <h2>Martial Arts Playlists</h2>
    </div>
    <div class="row">
      <div class="pricing-list">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center">
            <div class="prc-head bg-theme">
              <span>MMA TEAMS</span>
              <h4>30</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center">
            <div class="prc-head bg-theme">
              <span>VIDEO/TV SERIES</span>
              <h4>40</h4>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center">
            <div class="prc-head bg-theme">
              <span>MARTIAL ARTS</span>
              <h4>30</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="pricing-item text-center">
          <div class="prc-head bg-theme">
            <span>FIGHTER LIBRARY</span>
            <h4>23</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container hidden-xs hidden-sm">
    <div class="section-title text-black">
      <h2>Video Playlists</h2>
    </div>
    <div class="row">
      <div class="pricing-list">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center" style="min-height: 1185px;">
            <div class="prc-head bg-theme">
              <span>MMA TEAMS</span>
              <h4>30</h4>
            </div>
            <ul class="prc-list">
              <li>AKA</li>
              <li>Alliance MMA</li>
              <li>All Stars</li>
              <li>AMC Pankration</li>
              <li>ATT</li>
              <li>Arizona Combat Sports</li>
              <li>Blackzillians</li>
              <li>Cesar Gracie</li>
              <li>City Kickboxing</li>
              <li>Evolve MMA</li>
              <li>Elevation Fight Team</li>
              <li>Factory X</li>
              <li>Fight Ready</li>
              <li>Grudge Training Centre</li>
              <li>Jackson Wink</li>
              <li>Kings MMA</li>
              <li>London Shootfighters</li>
              <li>MMA Factory Paris</li>
              <li>MMA Lab</li>
              <li>Mjolnir MMA</li>
              <li>Nova Uniao</li>
              <li>Roufusport</li>
              <li>Sanford MMA</li>
              <li>SBG Ireland</li>
              <li>Strong Style MMA</li>
              <li>Syndicate MMA</li>
              <li>Team Alpha Male</li>
              <li>Team Kaobon</li>
              <li>Team Quest</li>
              <li>Tristar</li>
              <li>Xtreme Couture</li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center" style="min-height: 1185px;">
            <div class="prc-head bg-theme">
              <span>VIDEO/TV SERIES</span>
              <h4>40</h4>
            </div>
            <ul class="prc-list">
              <li>The Academy</li>
              <li>Ambitions of a Muay Thai Fighter</li>
              <li>Anatomy of a Fighter</li>
              <li>Art of Fighting</li>
              <li>Behind the Fighter</li>
              <li>Best of Siam</li>
              <li>Blood, Sweat and Chokes</li>
              <li>Coachella</li>
              <li>Contender Asian</li>
              <li>Daisy Fresh</li>
              <li>Damien Trainor</li>
              <li>Dog Days</li>
              <li>Dominican</li>
              <li>Drysdale BJJ</li>
              <li>Evolution of the Ground</li>
              <li>Evolution of Punk</li>
              <li>Fighting In The Age Of Loneliness</li>
              <li>Fightland</li>
              <li>Fight Quest</li>
              <li>Fighter Within</li>
              <li>Gilroy Wrestling</li>
              <li>Grass Roots Boxing</li>
              <li>Human Weapon</li>
              <li>History of MMA</li>
              <li>I Am A Warrior</li>
              <li>Inside the Fight</li>
              <li>Inside USA Gyms</li>
              <li>Kung Fu Shaolin</li>
              <li>Land of Warriors</li>
              <li>Living the Dream</li>
              <li>Man In The Arena</li>
              <li>Pride Decade</li>
              <li>One Warrior Series</li>
              <li>Rodolfo Viera BJJ</li>
              <li>Samurai Spirit</li>
              <li>Tiger Muay Thai</li>
              <li>Warrior Code</li>
              <li>Warriors of the Mongkon</li>
              <li>Way of the Warrior</li>
              <li>WhoaTV MMA Gyms</li>
              <li>Why I Fight</li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="pricing-item text-center" style="min-height: 1185px;">
            <div class="prc-head bg-theme">
              <span>MARTIAL ARTS PLAYLISTS</span>
              <h4>30</h4>
            </div>
            <ul class="prc-list">
              <li>Bellator</li>
              <li>Best of Women's MMA</li>
              <li>BJJ Documentaries</li>
              <li>BJJ Highlights</li>
              <li>Boxing Documentaries</li>
              <li>Boxing Highlights</li>
              <li>Brazilian MMA Highlights</li>
              <li>Bruce Lee</li>
              <li>Gracie Jiu-Jitsu</li>
              <li>Joseph Vincent</li>
              <li>Judo Documentaries</li>
              <li>Judo Highlights</li>
              <li>Machinemen</li>
              <li>Martial Arts Documentaries</li>
              <li>Martial Arts Highlights</li>
              <li>MMA Documentaries</li>
              <li>MMA Highlights</li>
              <li>MMA Pioneers</li>
              <li>Muay Thai Documentaries</li>
              <li>Muay Thai Highlights</li>
              <li>Muhammad Ali</li>
              <li>One FC</li>
              <li>Pride</li>
              <li>Razak Sports Films</li>
              <li>Ryan Jones Films</li>
              <li>Recommended</li>
              <li>Stuart Cooper Films</li>
              <li>UFC</li>
              <li>Wrestling Documentaries</li>
              <li>Wrestling Highlights</li>
              <li>UK MMA</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-3 col-xs-12">
        <div class="pricing-item text-center" style="min-height: 1185px;">
          <div class="prc-head bg-theme">
            <span>FIGHTER LIBRARY</span>
            <h4>23</h4>
          </div>
          <ul class="prc-list">
            <li>Anthony Pettis</li>
            <li>Amanda Nunes</li>
            <li>Anderson Silva</li>
            <li>BJ Penn</li>
            <li>Conor McGregor</li>
            <li>Daniel Cormier</li>
            <li>Demetrious Johnson</li>
            <li>Dominick Cruz</li>
            <li>Donald Cerrone</li>
            <li>Dustin Poirier</li>
            <li>Fedor Emelianenko</li>
            <li>Georges St-Pierre</li>
            <li>Israel Adesanya</li>
            <li>Jon Jones</li>
            <li>Jorge Masvidal</li>
            <li>Kamaru Usman</li>
            <li>Khabib Nurmagomedov</li>
            <li>Michael Bisping</li>
            <li>Nate Diaz</li>
            <li>Nick Diaz</li>
            <li>Ronda Rousey</li>
            <li>Valentina Shevchenko</li>
            <li>Zhang Weili</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="achivement-area ptb&#45;&#45;120 bg-theme
    text-center">
  <section id="summary2">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-star"></i></div>
            <h2>50</h2>
            <span>Recommended Videos</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-file-video-o"></i></div>
            <h2>5</h2>
            <span>Martial Arts Filmmakers</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-play-circle"></i></div>
            <h2>40</h2>
            <span>Martial Arts Video Series</span>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-12">
          <div class="achive-single">
            <div class="icon"><i class="fa fa-video-camera"></i></div>
            <h2>125</h2>
            <span>Video Playlists</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<section class="about-area ptb--110" id="faq">
  <div class="container">
    <div class="section-title text-black">
      <h2>FAQ</h2>
    </div>
    <div class="row">
      <div class="about-content">
        <div class="about-ft">
          <div class="ft-single">
            <div class="icon"><i class="fa fa-server"></i></div>
            <div class="ft-info">
              <h4>Do you host any videos?</h4>
              <p>No. All we are doing is finger-pointing to videos that already exist on the web, with enabled embedding
                permissions and are already shared publicly on many other websites. Moreover, any videos played from
                within our website will also register as a <i>hit</i> directly with the original video creator on their
                hosting platform and thereby contribute to any advertising revenue targets.</p>
            </div>
          </div>
          <div class="ft-single">
            <div class="icon"><i class="fa fa-copyright"></i></div>
            <div class="ft-info">
              <h4>What about copyright issues?</h4>
              <p><i>This is MMA</i> is not responsible for the accuracy, compliance, copyright, legality or any other
                aspect of the content of these video links. Please contact the appropriate video hosting sites directly
                with any legal/copyright concerns or if you wish to request the removal of a particular video. As <i>This
                  is MMA</i> provides a real-time view of videos that are hosted on third party sites, any changes will
                immediately be reflected on our website.</p></div>
          </div>
          <div class="ft-single">
            <div class="icon"><i class="fa fa-times"></i></div>
            <div class="ft-info">
              <h4>What if I don't want my video content available via this Website?</h4>
              <p>That would be the equivalent of asking Google or YouTube to exclude you from their search engine
                algorithms. One of the main goals of <i>This is MMA</i> is to bring new viewers to the amazing MMA and
                Martial Arts content that already exists on the web. We only provided an embedded link to your video and
                therefore help drive traffic and interest in your content.
                If you really don't want to be included switch off the embed video option permissions and this will
                prevent your video from playing within our website. Any permission changes will immediately be
                reflected.
              </p></div>
          </div>
          <div class="ft-single">
            <div class="icon"><i class="fa fa-file-video-o"></i></div>
            <div class="ft-info">
              <h4>There are some MMA/Martial Arts videos that I like - can you add them to this website?</h4>
              <p>We will be adding new video content regularly. If you contact us via our feedback form on our App we would
                be happy to look at them.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
