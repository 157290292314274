<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<div class="container">
  <div class="visible-sm visible-xs">
  <br/>
  <br/>
    <br/>
  </div>
<mat-tab-group style="margin-left: 15px">
  <mat-tab label="YouTube Channels">
    <mat-nav-list>
    <a mat-list-item href="{{link.url}}" target="_blank" *ngFor="let link of videoList"><img src="{{link.image}}" alt="{{ link.title }}" class="imageStyle"> {{ link.title }} </a>
  </mat-nav-list> </mat-tab>
  <mat-tab label="MMA Organizations">
    <mat-nav-list>
    <a mat-list-item href="{{link.url}}" target="_blank" *ngFor="let link of eventsList"><img src="{{link.image}}" alt="{{ link.title }}" class="imageStyle"> {{ link.title }} </a>
    </mat-nav-list>
  </mat-tab>
  <mat-tab label="MMA Podcasts">
    <mat-nav-list>

    <a mat-list-item href="{{link.url}}" target="_blank" *ngFor="let link of podcastList">  <img src="{{link.image}}" alt="{{ link.title }}" class="imageStyle"> {{ link.title }} </a>
    </mat-nav-list>
  </mat-tab>
  <!--<mat-tab label="MMA Teams">
    <mat-nav-list>
      <a mat-list-item href="{{link.url}}" target="_blank" *ngFor="let link of podcastList"><img src="{{link.image}}" alt="{{ link.title }}" class="imageStyle"> {{ link.title }} </a>
    </mat-nav-list>
  </mat-tab>-->
  <!--<mat-tab label="Martial Arts Websites">
    <mat-nav-list>
      <a mat-list-item href="{{link.url}}" target="_blank" *ngFor="let link of podcastList"> {{ link.title }} </a>
    </mat-nav-list>
  </mat-tab>-->
</mat-tab-group>
</div>
<app-footer></app-footer>
