import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';
import {ThisIsMMAService} from '../service/thisismma.service';
import {DomSanitizer} from '@angular/platform-browser';
import {merge, of} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {videos} from '../videos/videos';

@Component({
  selector: 'app-video-playlist',
  templateUrl: './video-playlist.component.html',
  styleUrls: ['./video-playlist.component.css']
})
export class VideoPlaylistComponent implements OnInit {

  category: any;
  videosTitle: string;
  dataSize: number;
  videos: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource: any[];

  constructor(private route: ActivatedRoute,
              private thisIsMMAService: ThisIsMMAService,
              private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const { category, videosTitle } = params;

      this.videosTitle = videosTitle;

      if (category !== null && category !== '') {
        this.category = category;
        this.getLinksByCategory();
      }
    });







    // this.getLinksByCompany();
  }

  // this method will link data to paginator
  linkListToPaginator() {
    // merge simply joins all this operators and creates an       //observable that listen to paginator page events
    merge(this.paginator.page).pipe(
      startWith({}),
      switchMap(() => {
        // creates an observable of sample data
        return of(this.videos);
      }))
      .subscribe(res => {
        const from = this.paginator.pageIndex * 10;
        const to = from + 10;
        this.dataSource = res.slice(from, to);
      });
  }

  getLinksByCategory() {
    this.videos = videos?.[this.category];
    this.linkListToPaginator();
    this.dataSize = this.videos?.length;
    /*this.thisIsMMAService.getLinksByCategory(this.category).subscribe(data => {
      this.videos = data;

    });*/
  }


  getVideoHref(imageLink: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageLink);
  }

  getLinkName(linkName: string) {
    const name = linkName?.length < 30 ? linkName || '' : linkName.substring(0, 30) + '...';
    return name;
  }
}
