export const videoPlaylists = {
  "highlightVideos": [{
    "title": "BJJ",
    "displayTitle": "BJJ Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "H8K4Eql5pDE",
    "categoryOne": "MARTIAL_ARTS_BJJ",
    "categoryTwo": null,
    "description": "",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Boxing",
    "displayTitle": "Boxing Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "j5M8mR9KA8Q",
    "categoryOne": "MARTIAL_ARTS_BOXING",
    "categoryTwo": "BOXING_FIGHTERS",
    "description": "",
    "type": "BOXING",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Judo",
    "displayTitle": "Judo Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "7h7_c1XuGc0",
    "categoryOne": "MARTIAL_ARTS_JUDO",
    "categoryTwo": null,
    "description": "",
    "type": "JUDO",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "MMA",
    "displayTitle": "MMA Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "jfb0IVW74Ag",
    "categoryOne": "MARTIAL_ARTS_MMA",
    "categoryTwo": "FIGHTER_HIGHLIGHTS",
    "description": "",
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Muay Thai",
    "displayTitle": "Muay Thai Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "HbPwjNy-VoQ",
    "categoryOne": "MARTIAL_ARTS_MUAY_THAI",
    "categoryTwo": "MUAY_THAI_FIGHTERS",
    "description": "",
    "type": "MUAY",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Martial Arts",
    "displayTitle": "Martial Arts Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "HHJkfjmGRrs",
    "categoryOne": "MARTIAL_ARTS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Wrestling",
    "displayTitle": "Wrestling Highlights",
    "playlistType": "HIGHLIGHT_VIDEOS",
    "image": "WsG_pgcDH3Q",
    "categoryOne": "MARTIAL_ARTS_WRESTLING",
    "categoryTwo": null,
    "description": "",
    "type": "WRESTLING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }],
  "documentaryVideos": [{
    "title": "BJJ",
    "displayTitle": "BJJ Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "hhOpdwyCIxk",
    "categoryOne": "DOCUMENTARY_BJJ",
    "categoryTwo": null,
    "description": "",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Boxing",
    "displayTitle": "Boxing Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "1wGqU8ZBTCs",
    "categoryOne": "DOCUMENTARY_BOXING",
    "categoryTwo": "DOCUMENTARY_BOXING_FIGHTER",
    "description": "",
    "type": "BOXING",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Judo",
    "displayTitle": "Judo Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "aTk0lmwgNKY",
    "categoryOne": "DOCUMENTARY_JUDO",
    "categoryTwo": null,
    "description": "",
    "type": "JUDO",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Martial Arts",
    "displayTitle": "Martial Arts Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "ExzDMT8DSCg",
    "categoryOne": "DOCUMENTARY_MARTIAL_ARTS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "MMA",
    "displayTitle": "MMA Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "vcdlpmdBhE0",
    "categoryOne": "DOCUMENTARY_MMA",
    "categoryTwo": "DOCUMENTARY_MMA_FIGHTER",
    "description": "",
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Muay Thai",
    "displayTitle": "Muay Thai Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "VA8OlJX4l38",
    "categoryOne": "DOCUMENTARY_MUAY_THAI",
    "categoryTwo": "DOCUMENTARY_MUAY_THAI_FIGHTER",
    "description": "",
    "type": "MUAY",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Wrestling",
    "displayTitle": "Wrestling Documentaries",
    "playlistType": "DOCUMENTARY_VIDEOS",
    "image": "tRhv4GYzmB4",
    "categoryOne": "DOCUMENTARY_WRESTLING",
    "categoryTwo": null,
    "description": "",
    "type": "WRESTLING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }],
  "playlistVideos": [{
    "title": "What's New",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "llBIml4lqS0",
    "categoryOne": "WHATS_NEW",
    "categoryTwo": null,
    "description": null,
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Recommended",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "yf5lLD3P5wQ",
    "categoryOne": "RECOMMENDED",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Motivation",
    "displayTitle": "Training Motivation",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "eGtF-zkeo9s",
    "categoryOne": "MOTIVATION",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Bellator",
    "displayTitle": "Best of Bellator",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "NV9AIEF2M6A",
    "categoryOne": "BELLATOR",
    "categoryTwo": null,
    "description": "",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Brazilian MMA",
    "displayTitle": "Best of Brazilian MMA",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "voyVIlFfmKo",
    "categoryOne": "BRAZILIAN_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Bruce Lee",
    "displayTitle": "Best of Bruce Lee",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "APx2yFA0-B4",
    "categoryOne": "BRUCE_LEE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Gracie Jiu-Jitsu",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "YZfjLyTy4h0",
    "categoryOne": "GRACIE",
    "categoryTwo": null,
    "description": "",
    "type": "BJJ",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "MMA Pioneers",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "rjk8lA5eveA",
    "categoryOne": "MMA_PIONEERS",
    "categoryTwo": null,
    "description": "",
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Muhammad Ali",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "pFKAAhSrplc",
    "categoryOne": "ALI",
    "categoryTwo": null,
    "description": "",
    "type": "BOXING",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "ONE Championship",
    "displayTitle": "Best of ONE Championship",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "7KkR9q3Hr9c",
    "categoryOne": "ONE_FC",
    "categoryTwo": null,
    "description": "",
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Pride",
    "displayTitle": null,
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "qix-KGKfEAg",
    "categoryOne": "PRIDE",
    "categoryTwo": null,
    "description": "",
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "UFC",
    "displayTitle": "Best of The UFC",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "BcPrLDHbQSc",
    "categoryOne": "UFC",
    "categoryTwo": null,
    "description": "Unofficial UFC video playlist from the best of Dailymotion, Vimeo, and YouTube",
    "type": null,
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "UK MMA",
    "displayTitle": "Best of UK MMA",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "huJ8rZgaHWI",
    "categoryOne": "UK_MMA",
    "categoryTwo": null,
    "description": "",
    "type": null,
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Women's MMA",
    "displayTitle": "Best of Women's MMA",
    "playlistType": "PLAYLIST_VIDEOS",
    "image": "LDIz71ZAfRs",
    "categoryOne": "WOMENS_MMA",
    "categoryTwo": null,
    "description": null,
    "type": "MMA",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }],
  "fightersVideos": [{
    "title": "Anthony Pettis",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "qc-3bCJtZSs",
    "categoryOne": "ANTHONY_PETTIS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Amanda Nunes",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "CEA7fasR4pg",
    "categoryOne": "AMANDA_NUNES",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Anderson Silva",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "31lhUrNdU2o",
    "categoryOne": "ANDERSON_SILVA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "BJ Penn",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "sGJj8PwpmHw",
    "categoryOne": "BJ_PENN",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Charles Oliveira",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "UMrhn2mTSeU",
    "categoryOne": "CHARLES_OLIVEIRA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Conor McGregor",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "7p1UT0OFb64",
    "categoryOne": "CONOR_MCGREGOR",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Dustin Poirier",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "7dYe2xXgUlQ",
    "categoryOne": "DUSTIN_POIRIER",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Daniel Cormier",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "SOo-mBiBeb4",
    "categoryOne": "DANIEL_CORMIER",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Demetrious Johnson",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "AZr6g1GdTdA",
    "categoryOne": "DEMETRIOUS_JOHNSON",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Dominick Cruz",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "0wgcZ4x8d3M",
    "categoryOne": "DOMINICK_CRUZ",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Donald Cerrone",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "Xe3DQwRVN6g",
    "categoryOne": "DONALD_CERRONE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Fedor Emelianenko",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "RmEFaMc8n5A",
    "categoryOne": "FEDOR",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Georges St-Pierre",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "NZo_1BcQfao",
    "categoryOne": "GSP",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Israel Adesanya",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "C4srwzsbri4",
    "categoryOne": "ISRAEL_ADESANYA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Jon Jones",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "0EyvOsPV1bY",
    "categoryOne": "JON_JONES",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Jorge Masvidal",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "MNfs8fNwmjk",
    "categoryOne": "JORGE_MASVIDAL",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Kamaru Usman",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "h_fx8CI8jo0",
    "categoryOne": "KAMARU_USMAN",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Khabib Nur...",
    "displayTitle": "Khabib Nurmagomedov",
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "_FakdYGQ1io",
    "categoryOne": "KHABIB_NURMAGOMEDOV",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Michael Bisping",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "jRz2IG9njW8",
    "categoryOne": "MICHAEL_BISPING",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Nate Diaz",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "e_7K5rsy_FA",
    "categoryOne": "NATE_DIAZ",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Nick Diaz",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "lWbSBXiVWqU",
    "categoryOne": "NICK_DIAZ",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Ronda Rousey",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "156yd65csno",
    "categoryOne": "RONDA_ROUSEY",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Tony Ferguson",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "thtcCo7KzdA",
    "categoryOne": "TONY_FERGUSON",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Valentina Shev...",
    "displayTitle": "Valentina Shevchenko",
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "5zTJIyzaG2s",
    "categoryOne": "VALENTINA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Zhang Weili",
    "displayTitle": null,
    "playlistType": "FIGHTERS_VIDEOS",
    "image": "BhW1tB1ien0",
    "categoryOne": "ZHANG_WEILI",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": false,
    "showAds": true
  }],
  "mmaTeamsVideos": [{
    "title": "AKA",
    "displayTitle": "American Kickboxing Academy",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "dbzwPrx-EaM",
    "categoryOne": "AKA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Alliance MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "Ir-1JpG8Lig",
    "categoryOne": "ALLIANCE_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "All Stars",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "zsGmUTIE_n4",
    "categoryOne": "ALL_STARS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "AMC Pankration",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "oVvIE-mOKe0",
    "categoryOne": "AMC_PANKRATION",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "ATT",
    "displayTitle": "American Top Team",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "_necoBxwmsQ",
    "categoryOne": "ATT",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Arizona Combat",
    "displayTitle": "Arizona Combat Sports",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "Xe3qmuAap-c",
    "categoryOne": "ARIZONA_COMBAT_SPORTS",
    "categoryTwo": null,
    "description": null,
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Blackzillians",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "DjUzvCR18CE",
    "categoryOne": "BLACKZILLIANS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }, {
    "title": "Cesar Gracie",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "4rWl0KgAstE",
    "categoryOne": "CESAR_GRACIE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "City Kickboxing",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "wmvD0XqmJEw",
    "categoryOne": "CITY_KICKBOXING",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Elevation FT",
    "displayTitle": "Elevation Fight Team",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "oeStR_RZFWI",
    "categoryOne": "ELEVATION_FIGHT_TEAM",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Evolve MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "1ZwuazGQ7a8",
    "categoryOne": "EVOLVE_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Factory X",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "KEF7vIxhy3s",
    "categoryOne": "FACTORY_X",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Grudge",
    "displayTitle": "Grudge Training Centre",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "mRzTuiR1OKQ",
    "categoryOne": "GRUDGE_TRAINING_CENTRE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Jackson Wink",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "k4O-AXbcyqM",
    "categoryOne": "JACKSON_WINK",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Kings MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "Dueo38t8f8E",
    "categoryOne": "KINGS_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Ldn Shootfighters",
    "displayTitle": "London Shootfighters",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "V9a3Kg91fl4",
    "categoryOne": "LONDON_SHOOTFIGHTERS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "MMA Factory",
    "displayTitle": "MMA Factory Paris",
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "5y7uzzHMUUo",
    "categoryOne": "MMA_FACTORY_PARIS",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "MMA Lab",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "n6K7dxqNkII",
    "categoryOne": "MMA_LAB",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Mjolnir MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "zW1sYypgYZc",
    "categoryOne": "MJOLNIR_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Nova Uniao",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "eI0AeQQegh0",
    "categoryOne": "NOVA_UNIAO",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Roufusport",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "WFqkwyb3Q2s",
    "categoryOne": "ROUFUSPORT",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Sanford MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "OuwGpLC0L6c",
    "categoryOne": "HARD_KNOCKS_365",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "SBG Ireland",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "pqjgvrItkII",
    "categoryOne": "SBG_IRELAND",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Strong Style MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "immT-UG-New",
    "categoryOne": "STRONG_STYLE_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Syndicate MMA",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "HSkeSj0spFc",
    "categoryOne": "SYNDICATE_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Team Alpha Male",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "6rXUZAw4koI",
    "categoryOne": "TEAM_ALPHA_MALE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Team Kaobon",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "sixIt4UMSDY",
    "categoryOne": "TEAM_KAOBON_MMA",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Team Quest",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "FeQdr1W3LQk",
    "categoryOne": "TEAM_QUEST",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Tristar",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "kMrDAC9P1I4",
    "categoryOne": "TRISTAR",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }, {
    "title": "Xtreme Couture",
    "displayTitle": null,
    "playlistType": "MMA_TEAMS_VIDEOS",
    "image": "jHzvaCRm58c",
    "categoryOne": "XTREME_COUTURE",
    "categoryTwo": null,
    "description": "",
    "type": "",
    "isTabs": true,
    "isTeams": true,
    "showAds": false
  }],
  "tvSeriesVideos": [{
    "title": "The Academy",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "tmec__UZ_14",
    "categoryOne": "TV_SERIES_ACADEMY",
    "categoryTwo": null,
    "description": "The Academy: featuring high quality, cinematic, short-films showcasing Brazilian Jiu Jitsu culture, technique, and history. From filmmaker and BJJ practitioner, Matthew Hickney",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Ambitions of a Mu...",
    "displayTitle": "Ambitions of a Muay Thai Fighter",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "gKdoqR6-yao",
    "categoryOne": "TV_SERIES_AMBITIONS_OF_A_MUAY_THAI_FIGHTER",
    "categoryTwo": null,
    "description": "Documentary short film series on leading UK Muay Thai fighters",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Anatomy of a Fighter",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "JmD-XnBdB1c",
    "categoryOne": "TV_SERIES_ANATOMY_OF_A_FIGHTER",
    "categoryTwo": null,
    "description": "The Anatomy of a Fighter documentary series brings a unique look to the world of MMA and offers intimate access to fighters, teams and MMA events. Check out the most recent updates from The Anatomy of a Fighter in our latest > channels section",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Art of Fighting",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "hWH30YRp9dc",
    "categoryOne": "TV_SERIES_FOXTEL_ART_OF_FIGHTING",
    "categoryTwo": null,
    "description": "Foxtel\"s mini-series on the art of MMA",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Behind the Fighter",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "kwifMVNu6RU",
    "categoryOne": "TV_SERIES_BEHIND_THE_FIGHTER",
    "categoryTwo": null,
    "description": "Behind The Fighter is a UK MMA/Muay Thai documentary series from Fight Life TV featuring the hopes, dreams and ambitions of many of the UK\"s best MMA and Muay Thai talent",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Best of Siam",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "QsSS72cLDXo",
    "categoryOne": "TV_SERIES_BEST_OF_SIAM",
    "categoryTwo": null,
    "description": "Inspiring film series from Siam Muay Thai events produced by Hanuman Prod",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Blood Sweat Chokes",
    "displayTitle": "Blood Sweat & Chokes",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "Jt2nISk6n_I",
    "categoryOne": "TV_SERIES_BLOOD_SWEAT_CHOKES",
    "categoryTwo": null,
    "description": "Slick new web series following three young fighters as they journey through the UK\"s underground MMA scene full of grimy gyms, showgirls and dodgy promoters. As they hustle to reach the glitzy world of the UFC, we get a sense of the brotherhood and struggles involved in trying to achieve their goals",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Coachella",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "bVafeWa0y0g",
    "categoryOne": "TV_SERIES_COACHELLA",
    "categoryTwo": null,
    "description": "Roy Dean is a martial artist instructor, with black belts in Kodokan Judo, Aikikai Aikido, Seibukan Jujutsu, and Brazilian Jiu Jitsu. With over 25 years of experience, Dean is able to explain powerful insights into the art of jiu jitsu",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Contender Asia",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "L6YaZEbvvSs",
    "categoryOne": "TV_SERIES_THE_CONTENDER",
    "categoryTwo": null,
    "description": "The Contender Asia was a reality-based television series, first broadcast in 2008, that followed 16 Muay Thai middleweight fighters from 12 countries as they compete in a series of outdoor challenges and sanctioned matches",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Damien Trainor",
    "displayTitle": "Damien Trainor Documentary",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "f9bL3kn_dwM",
    "categoryOne": "TV_SERIES_DAMIEN_TRAINOR_DOCUMENTARY",
    "categoryTwo": null,
    "description": "Muay Thai documentary series which looks at Damien Trainor\"s journey as a martial artist, becoming a professional fighter and multiple world champion",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Daisy Fresh",
    "displayTitle": "Daisy Fresh: An American Jiu-Jitsu Story",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "t03TqJ7-Oiw",
    "categoryOne": "TV_SERIES_DAISY_FRESH",
    "categoryTwo": null,
    "description": "The tale of a group of athletes living in the gym at Pedigo Submission Fighting and chasing their dream of becoming jiu-jitsu world champions",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Dog Days",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "q5BwwQhshxE",
    "categoryOne": "TV_SERIES_DOG_DAYS",
    "categoryTwo": null,
    "description": "Documentary series which chronicles the training program of the University of Minnesota\"s 2004-2005 wrestling team, coached by J Robinson",
    "type": "WRESTLING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Dominican",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "9_mRAc-eNaQ",
    "categoryOne": "TV_SERIES_DOMINICAN",
    "categoryTwo": null,
    "description": "In this four part series, get up close and personal with professional MMA fighter and pride of the Dominican Republic, Alex Garcia",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Drysdale BJJ",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "TnT63L0Nq8w",
    "categoryOne": "TV_SERIES_DRYSDALE_BJJ_DOC",
    "categoryTwo": null,
    "description": "A documentary following Brazilian Jiu Jitsu master Robert Drysdale\"s superfight in Barcelona against Ronaldo Jacare Souza",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Evo of the Ground",
    "displayTitle": "Evolution of the Ground",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "3QJSsXGjpi0",
    "categoryOne": "TV_SERIES_EVOLUTION_OF_THE_GROUND",
    "categoryTwo": null,
    "description": "BJJ documentary series on the Eddie Bravo Invitational no-gi grappling tournament",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Evolve Tryouts",
    "displayTitle": "Evolve Fight Team Global Tryouts",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "1ZwuazGQ7a8",
    "categoryOne": "TV_SERIES_EVOLVE_FIGHT_TEAM",
    "categoryTwo": null,
    "description": "For the first time in history, Evolve MMA, Asia’s top martial arts organization, holds global tryouts to recruit new members to join the EVOLVE Fight Team. Athletes from all corners of the world leave everything behind to chase the opportunity of a lifetime to join Asia’s most decorated professional fight team",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Evolution of Punk",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "dQ7B8KfG7Us",
    "categoryOne": "TV_SERIES_EVOLUTION_OF_PUNK",
    "categoryTwo": null,
    "description": "Former pro wrestler CM Punk begins his training camp for his UFC debut",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Fighting in The Ag...",
    "displayTitle": "Fighting In The Age Of Loneliness",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "5oNB6tlSZ2A",
    "categoryOne": "TV_SERIES_FIGHTING_IN_THE_AGE_OF_LONELINESS",
    "categoryTwo": null,
    "description": "Fighting in the Age of Loneliness is a SB Nation documentary series on the evolution of MMA over the past 20 years",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Fightland",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "geoA0uMJMiQ",
    "categoryOne": "TV_SERIES_FIGHTLAND",
    "categoryTwo": null,
    "description": "Fightland is VICE\"s take on this ever-evolving MMA culture exploring the international influences that make up the world of MMA",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Fight Quest",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "6Y4H0pGl0sA",
    "categoryOne": "TV_SERIES_FIGHT_QUEST",
    "categoryTwo": null,
    "description": "Fight Quest was a television show on the Discovery Channel that began airing weekly in 2008. The show followed Jimmy Smith and Doug Anderson as they travel around the world learning different styles of martial arts, spending five days training with notable masters of the styles they are studying, before exhibiting what they have learned in a final demonstration and/or fight",
    "type": "ALL",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Fighter Within",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "Z4qtKuo7_E4",
    "categoryOne": "TV_SERIES_THE_FIGHTER_WITHIN",
    "categoryTwo": null,
    "description": "Boxing motivational highlight series",
    "type": "BOXING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Grass Roots Boxing",
    "displayTitle": "Grass Roots Boxing",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "Fz1wigSCx20",
    "categoryOne": "TV_SERIES_GRASS_ROOTS_BOXING",
    "categoryTwo": null,
    "description": "Inspiring documentary series which focuses on grass roots boxing in Scotland, featuring Sparta Boxing Academy from Grangemouth. Filmed and edited by BeeZom Productions",
    "type": "BOXING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Gilroy Wrestling",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "zPP1RxYpVj4",
    "categoryOne": "TV_SERIES_GILROY_WRESTLING",
    "categoryTwo": null,
    "description": "Gilroy Wrestling chronicles the journey of four outstanding wrestlers from Gilroy High School. All four wrestlers, along with their teammates, started wrestling at a young age. This documentary followed the program from 2011-2016 and documented the program\"s unbelievable section title run as well",
    "type": "WRESTLING",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Human Weapon",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "mTGxj6zOzEU",
    "categoryOne": "TV_SERIES_HUMAN_WEAPON",
    "categoryTwo": null,
    "description": "Human Weapon was a television show on The History Channel that premiered on July 20, 2007. The hosts, Jason Chambers and Bill Duff, traveled across the world studying the unique martial arts, or styles of fighting, that have origins in the region",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "History of MMA",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "njO_9mxV890",
    "categoryOne": "TV_SERIES_HISTORY_OF_MMA",
    "categoryTwo": null,
    "description": "Documentary series on the history of MMA by English film director Bobby Razak",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "I Am A Warrior",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "2xR1QL0bPJo",
    "categoryOne": "TV_SERIES_I_AM_A_WARRIOR",
    "categoryTwo": null,
    "description": "The \"I AM A WARRIOR\" series captures the essence of what it means to be a warrior in life: it does not matter where you start in life. It does not matter where you are right now. You always have the power to conquer your circumstances and achieve the life of your dreams",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Inside the Fight",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "DOKr6OluXzQ",
    "categoryOne": "TV_SERIES_INSIDE_THE_FIGHT",
    "categoryTwo": null,
    "description": "Inspiring Muay Thai documentary series produced by photographer Jeff Dojillo for 7S Propaganda",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Inside USA Gyms",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "wCG20Bn3eVM",
    "categoryOne": "TV_SERIES_INSIDE_AMERICAN_GYMS",
    "categoryTwo": null,
    "description": "Video tours inside America\"s leading MMA gyms",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Kung Fu Shaolin",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "YDR31_nh70Q",
    "categoryOne": "TV_SERIES_KUNG_FU_SHAOLIN",
    "categoryTwo": null,
    "description": "For 1,500 years, Shaolin Kung Fu has been the most famous of the Chinese martial arts. Learn all about it in the documentary series, The Kung Fu Shaolin. ",
    "type": "MartialArts",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Land of Warriors",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "l027P8kkQDw",
    "categoryOne": "TV_SERIES_LAND_OF_WARRIORS",
    "categoryTwo": null,
    "description": "RT Sport presents the serialized documentary film ‘Dagestan: Land of Warriors’ exploring one of Russia’s North Caucasus regions which, despite its modest size and population, it produces top-level martial artists year-on-year",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Living the Dream",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "2mGWiwLxCww",
    "categoryOne": "TV_SERIES_LIVING_THE_DREAM",
    "categoryTwo": null,
    "description": "Living the Dream was a MMA reality show, based in Malaysia which was hosted by martial arts author, Antonio Graceffo. The purpose of the show was to give a chance for novices to gain entrance into the world of professional fighting",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Man In The Arena",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "272-4LmrVdQ",
    "categoryOne": "TV_SERIES_MAN_IN_THE_ARENA",
    "categoryTwo": null,
    "description": "A feature length documentary about UFC fighter Spencer \"The King\" Fisher. Directed by Ross Guidici & Gary Steuber",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Motivation Series",
    "displayTitle": "Motivation Series - Mulligan Brothers",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "cAWMy50kyX4",
    "categoryOne": "TV_SERIES_MULLIGAN_BROTHERS_MOTIVATION",
    "categoryTwo": null,
    "description": "Inspiring 7 part motivational series from the Mulligan Brothers",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Pride Decade",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "CFQ0V8TcyvY",
    "categoryOne": "TV_SERIES_PRIDE_DECADE",
    "categoryTwo": null,
    "description": "Chronicling a decade in the the history of one of the most exciting organizations in the history of MMA",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "One Warrior Series",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "Csjy1z58ews",
    "categoryOne": "TV_SERIES_ONE_FC_WARRIOR_SERIES",
    "categoryTwo": null,
    "description": "Former UFC fighter Rich Franklin searches for the next generation of MMA fighters in Asia",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Rodolfo Viera BJJ",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "ytFRICyuC2Y",
    "categoryOne": "TV_SERIES_RODOLFO_VIEIRA",
    "categoryTwo": null,
    "description": "A Jits TV special documentary on 2011 absolute world champion phenom, Rodolfo Vieira and his lifestyle in Rio de Janeiro",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Samurai Spirit",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "e7Pa4n4Vn1k",
    "categoryOne": "TV_SERIES_SAMURAI_SPIRIT",
    "categoryTwo": null,
    "description": "Documentary series on the Samurai spirit of Judo",
    "type": "JUDO",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Tiger Muay Thai",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "w2fHy_blUE0",
    "categoryOne": "TV_SERIES_TIGER_MUAY_THAI",
    "categoryTwo": null,
    "description": "In the Tiger Muay Thai tryout series 26 athletes from around the globe compete against each other for a spot on their professional fight team and a year\"s scholarship training at our camp in the tropical land of Phuket, Thailand",
    "type": "",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Warrior Code",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "sC8iu1sYUPs",
    "categoryOne": "TV_SERIES_WARRIOR_CODE",
    "categoryTwo": null,
    "description": "UFC fighters discuss the elements of fighting that make up their warrior code",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Warriors of the Mo..",
    "displayTitle": "Warriors of the Mongkon",
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "H5lu17NslpY",
    "categoryOne": "TV_SERIES_WARRIORS_OF_THE_MONGKON",
    "categoryTwo": null,
    "description": "Warriors of the Mongkon is a behind the scenes Muay Thai documentary directed by Owen Rennie. The life and training regime of leading Muay Thai fighters are highlighted, showing the demanding mental and physical pressure of the sport",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Way of the Warrior",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "-GzVpSypCFY",
    "categoryOne": "TV_SERIES_WAY_OF_THE_WARRIOR",
    "categoryTwo": null,
    "description": "Way of the warrior was a BBC martial arts documentary series from 1983 narrated by Dennis Waterman",
    "type": "MartialArts",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "WhoaTV MMA Gyms",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "nPTmB-gZuoo",
    "categoryOne": "TV_SERIES_WHOA_TV_GYMS",
    "categoryTwo": null,
    "description": "WHOA! TV tours some of the UK\"s most successful MMA gyms",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }, {
    "title": "Why I Fight",
    "displayTitle": null,
    "playlistType": "TV_SERIES_VIDEOS",
    "image": "yhFUxZVbuSs",
    "categoryOne": "TV_SERIES_WHY_I_FIGHT",
    "categoryTwo": null,
    "description": "UFC fighters discuss their motivation for pursuing a MMA career",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": true
  }],
  "filmProducersVideos": [{
    "title": "Joseph Vincent",
    "displayTitle": "Joseph Vincent",
    "playlistType": "FILM_PRODUCER_VIDEOS",
    "image": "Zj7oPwPALWQ",
    "categoryOne": "TV_SERIES_BORED_FILM",
    "categoryTwo": null,
    "description": "Joseph Vincent produces the excellent Original Bored Film series which features Mini-documentary videos on leading Boxers, Martial Artists and MMA fighters. Check out the most recent updates from Joseph Vincent in our latest > channels section",
    "type": "BOXING",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }, {
    "title": "Machinemen",
    "displayTitle": null,
    "playlistType": "FILM_PRODUCER_VIDEOS",
    "image": "sHG289hvdss",
    "categoryOne": "MACHINEMAN",
    "categoryTwo": null,
    "description": "Machinemen has produced some of the most inspiring MMA videos over the past 20 years which truly captured the spirit of the MMA culture and way of life",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }, {
    "title": "Razak Sports Films",
    "displayTitle": null,
    "playlistType": "FILM_PRODUCER_VIDEOS",
    "image": "2ryNS3bWlLw",
    "categoryOne": "RAZAK_SPORTS_FILMS",
    "categoryTwo": null,
    "description": "Bobby Razak is an English film director and producer, best known for his work in the genres of Mixed Martial Arts and Extreme sports. Check out the most recent updates from Razak Sports Films in our latest > channels section and also the excellent History of MMA documentary series in our video series section",
    "type": "MMA",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }, {
    "title": "Ryan Jones Films",
    "displayTitle": null,
    "playlistType": "FILM_PRODUCER_VIDEOS",
    "image": "dd4RmriQWPE",
    "categoryOne": "RYAN_JONES",
    "categoryTwo": null,
    "description": "Ryan Jones is a freelance filmmaker & video editor specialising in sport and fitness based videography. Check out the most recent updates from Ryan Jones Films in our latest > channels section",
    "type": "MUAY",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }, {
    "title": "Stuart Cooper",
    "displayTitle": "Stuart Cooper Films",
    "playlistType": "FILM_PRODUCER_VIDEOS",
    "image": "zqfCvgPssp8",
    "categoryOne": "STUART_COOPER",
    "categoryTwo": null,
    "description": "Stuart Cooper is a Jiu Jitsu Black Belt and video creator known for making some of best online BJJ documentaries and short films. Check out the most recent updates from Stuart Cooper Films in our latest > channels section",
    "type": "BJJ",
    "isTabs": false,
    "isTeams": false,
    "showAds": false
  }]
}
