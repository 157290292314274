// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCjVmSrKB1i0srL8qHsxtZaRsqyST3HfgE',
    authDomain: 'this-is-mma-videos.firebaseapp.com',
    projectId: 'this-is-mma-videos',
    storageBucket: 'this-is-mma-videos.appspot.com',
    messagingSenderId: '43488147351',
    appId: '1:43488147351:web:cbe9359563a1ca2b7f76d2',
    measurementId: 'G-C2RB9DFBYY'
  }
};

