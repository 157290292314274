<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<!-- pricing area start -->
<section class="pricing-area ptb--100">
  <div class="container">
    <div class="section-title text-black">
      <h2>Help</h2>
    </div>
    <p>This is MMA provides a real-time view of videos that are hosted on third
      party sites. Therefore, from time to time, some of the videos listed will get blocked or removed by the
      video owner and may appear as a blank image in our lists.
    </p>
  </div>
</section>
<app-footer></app-footer>
