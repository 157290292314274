import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VideosComponent} from './videos/videos.component';
import {LinksComponent} from './links/links.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {HomeComponent} from './home/home.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {HttpClientModule} from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {SafePipe} from './safe.pipe';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeadlineComponent} from './components/headline/headline.component';
import {VideosListComponent} from './components/videos-list/videos-list.component';
import {VideoPlaylistComponent} from './video-playlist/video-playlist.component';
import {VideoPlaylistTabsComponent} from './video-playlist-tabs/video-playlist-tabs.component';
import {AppsInfoComponent} from './apps-info/apps-info.component';
import {HelpComponent} from './help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    VideosComponent,
    LinksComponent,
    DisclaimerComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SafePipe,
    HeadlineComponent,
    VideosListComponent,
    VideoPlaylistComponent,
    VideoPlaylistTabsComponent,
    AppsInfoComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    ScrollingModule,
    HttpClientModule,
    MatTableModule,
    MatCardModule,
    MatTabsModule,
    MatListModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
