<div class="container" id="headline1">
  <div class="slider-content d-flex flex-center">
    <div class="col-md-8 col-sm-9 col-xs-12 d-flex flex-center" style="padding: 0px">
      <div class="slider-inner hidden-sm hidden-xs">
        <h2>MMA Lifestyle, Heritage and Culture</h2>
        <br/>
        <h4>This is MMA: bringing the MMA world to you!</h4>
        <br/>
        <div class="btn-area">
          <a href="/latest" title="Download on the App Store"><i
            class="fa fa-apple"></i> Latest</a>&nbsp;
          <a href="/videos" title="Get it on Google Play"><i
            class="fa fa-android"></i> Videos</a>
          <a href="/links"> <i class="fa fa-play"></i> Links</a>
        </div>
      </div>
      <div class="slider-inner visible-xs visible-sm">
        <h4>MMA Lifestyle, Heritage and Culture</h4>
        <br/>
        <h4>Bringing the MMA world to you!</h4>
        <br/>
        <div class="btn-area">
          <a href="https://apps.apple.com/app/this-is-mma/id1546766103" title="Download on the App Store"><i
            class="fa fa-apple"></i> App Store Download</a><br/><br/>
          <a href="https://play.google.com/store/apps/details?id=com.thisismma" title="Get it on Google Play"><i
            class="fa fa-android"></i> Google Play Download</a><br/><br/>
          <a href="#" title="Play App Demo"> <i class="fa fa-play"></i> App Demo</a>
        </div>
      </div>
    </div>
    <div class="col-md-4 hidden-sm hidden-xs">
      <div class="slider-m-img">
        <img src="../../../assets/img/mma3.gif" alt="mobile image"/>
      </div>
    </div>
  </div>
</div>

