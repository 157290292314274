
<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<!-- pricing area start -->
<section class="pricing-area ptb--100">
  <div class="container">
    <div class="section-title text-black">
      <h2>Disclaimer</h2>
    </div>
  <p>This is MMA does not host any of the videos that you can access via this website. We only provide embedded video links
    that come directly from third party video hosting sites including Dailymotion, Vimeo and YouTube. These
    embedded videos are freely available and already shared publicly on many other websites. This is MMA is therefore not responsible for the
    accuracy, compliance, copyright, legality or any other aspect of the content of these video links.
    Please contact the appropriate video hosting sites directly with any legal/copyright concerns or if you wish to request the removal of a particular video.
  </p>
  </div>
</section>
<app-footer></app-footer>

