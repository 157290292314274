import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ThisIsMMAService} from '../service/thisismma.service';
import { videoPlaylists } from './playlists.js';
import { videos } from './videos.js';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  constructor(private service: ThisIsMMAService ) { }

  videosPlaylists: any;
  videos: any;

  ngOnInit(): void {
    this.getVideoPlaylists();
    this.getAllLinks();
  }

  getVideoPlaylists() {
    this.videosPlaylists = videoPlaylists;
   /* this.service.getVideoPlaylists().subscribe(data => {
      ///console.log(data);
      this.videosPlaylists = videos;
    });*/
  }

  getAllLinks(){
    this.videos = videos;
   /* this.service.getAll().subscribe(data => {
      //console.log(data);
      this.videos = data;

    });*/
  }

  getLinksByCategory(category) {
    this.service.getLinksByCategory(category).subscribe(data => {
    });
  }

}
