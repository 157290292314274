
<div class="container">
  <div class="section-title text-black">
    <h2>{{videosTitle}}</h2>
  </div>
  <div class="row">
    <div class="pricing-list">
      <div class="col-md-3 col-sm-3 col-xs-12" style="padding:10px;cursor: pointer;" *ngFor="let item of videos; let i = index">


        <div class="pricing-item text-center" routerLink='/videoPlaylist'
             [queryParams]="{category: item.categoryOne, videosTitle: item.title + ' '  + videosTitle}" *ngIf="!item.isTabs">
          <div class="prc-head bg-theme">
            <span>{{item.title}}</span>
          </div>
          <img class="imageStyle"
               src="https://img.youtube.com/vi/{{item.image}}/hqdefault.jpg"
               alt="Video image"/>
          <h5 style="font-weight: bolder">{{getNumberOfVideos(item.categoryOne, item.categoryTwo)}} Videos</h5>
        </div>

      <div class="pricing-item text-center" routerLink='/videoPlaylistTabs'
           [queryParams]="{category: item.categoryOne, categoryTwo: item.categoryTwo, videosTitle: item.title + ' '  + videosTitle}" *ngIf="item.isTabs">
        <div class="prc-head bg-theme">
          <span>{{item.title}}</span>
        </div>
        <img class="imageStyle"
             src="https://img.youtube.com/vi/{{item.image}}/hqdefault.jpg"
             alt="Video image"/>
        <h5 style="font-weight: bolder">{{getNumberOfVideos(item.categoryOne, item.categoryTwo)}} Videos</h5>
      </div>
    </div>

    </div>
  </div>
</div>
