<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<section class="pricing-area ptb--100">
  <div class="container">
    <div class="section-title text-black">
      <h2>Download Our App</h2>
    </div>
    <p>As well as this website <i>This is MMA</i> also has a fantastic app for you to download and enjoy.
      This offers even more features than our website including:
    </p>
    <ul>
      <li style="padding: 15px"><b>MMA Schedule:</b> We integrate with ESPN's MMA calendar to keep you upto date with all forthcoming MMA events.</li>
      <li style="padding: 15px"><b>My Library:</b> Add any video to your library and watch at your convenience.</li>
      <li style="padding: 15px"><b>MMA Headlines:</b> Keep up to date with the latest MMA news with our headlines page.</li>
      <li style="padding: 15px"><b>RSS News Feeds:</b> We have over 50 RSS news feeds from the main MMA websites and organisations.</li>
      <li style="padding: 15px"><b>Feedback Form:</b> Suggest a MMA/Martial Arts video that you want added to our website or something else that can help improve our app experience.</li>
    </ul>
    <br/>
    <br/>
    <div class="btn-area-red-2 hidden-sm hidden-xs" style="margin-left: 50px">
      <a href="https://apps.apple.com/app/this-is-mma/id1546766103" title="Download on the App Store"><i
        class="fa fa-apple"></i> App Store Download</a>&nbsp;&nbsp;
      <a href="https://play.google.com/store/apps/details?id=com.thisismma" title="Get it on Google Play"><i
        class="fa fa-android"></i> Google Play Download</a>&nbsp;&nbsp;
      <a href="https://www.youtube.com/watch?v=IPN2FXNb6nw" title="Watch App Demo" target="_blank"> <i
        class="fa fa-play"></i> Watch Our App Demo</a>&nbsp;
      <a href="https://thisismma.app/" title="This is MMA App Website" target="_blank"> <i
        class="fa fa-list"></i> App Website</a>
    </div>
    <div class="btn-area-red-2 visible-sm visible-xs" style="margin-left: 50px">
      <a href="https://apps.apple.com/app/this-is-mma/id1546766103" title="Download on the App Store"><i
        class="fa fa-apple"></i> App Store Download</a>&nbsp;&nbsp;<br/><br/>
      <a href="https://play.google.com/store/apps/details?id=com.thisismma" title="Get it on Google Play"><i
        class="fa fa-android"></i> Google Play Download</a>&nbsp;&nbsp;<br/><br/>
      <a href="https://www.youtube.com/watch?v=IPN2FXNb6nw" title="Watch App Demo" target="_blank"> <i
        class="fa fa-play"></i> Watch Our App Demo</a><br/><br/>
      <a href="https://thisismma.app/" title="This is MMA App Website" target="_blank"> <i
        class="fa fa-list"></i> App Website</a>
    </div>
  </div>
</section>
<app-footer></app-footer>
