import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThisIsMMAService {

  // private API_ROOT = '//localhost:8080';
  private API_ROOT = '//thisismma-env.eba-gvxcr5y2.us-east-1.elasticbeanstalk.com';
  private GET_LINKS_BY_CATEGORY_API = this.API_ROOT + '/getLinksByCategory';
  private GET_LINKS_BY_COMPANY_API = this.API_ROOT + '/getLinksByCompany';
  private GET_VIDEOS_PLAYLIST_API = this.API_ROOT + '/getAllVideos';
  private GET_ALL_LINKS_API = this.API_ROOT + '/getAllLinks';
  private GET_ENTITY_API = this.API_ROOT + '/getEntity';
  private CATEGORY_PARAM = '?category=';
  private COMPANY_PARAM = '?company=';
  private ID_PARAM = '?id=';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<any> {
    return this.http.get(this.GET_ALL_LINKS_API).pipe(
      catchError(this.handleError(this.GET_ALL_LINKS_API, []))
    );
  }

  getLinksByCategory(category: string): Observable<any> {
    return this.http.get(this.GET_LINKS_BY_CATEGORY_API + this.CATEGORY_PARAM + category).pipe(
      catchError(this.handleError(this.GET_LINKS_BY_CATEGORY_API, []))
    );
  }

  getVideoPlaylists(): Observable<any> {
    return this.http.get(this.GET_VIDEOS_PLAYLIST_API).pipe(
      catchError(this.handleError(this.GET_VIDEOS_PLAYLIST_API, []))
    );
  }

  getLinksByCompany(company: string): Observable<any> {
    return this.http.get(this.GET_LINKS_BY_COMPANY_API + this.COMPANY_PARAM + company).pipe(
      catchError(this.handleError(this.GET_LINKS_BY_COMPANY_API, []))
    );
  }

  getLinkById(id: string): Observable<any> {
    return this.http.get(this.GET_ENTITY_API + this.ID_PARAM + id).pipe(
      catchError(this.handleError(this.GET_ENTITY_API, []))
    );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console
      return of(result as T);
    };
  }
}
