import { Component, OnInit } from '@angular/core';
import { newsList, videoList, podcastList, eventsList } from './index.js';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  videoList: any;
  podcastList: any;
  eventsList: any;

  constructor() { }

  ngOnInit(): void {
    this.videoList = videoList;
    this.podcastList = podcastList;
    this.eventsList = eventsList;
  }

}
