<section class="slider-area-small" id="home">
  <app-header></app-header>
</section>
<mat-tab-group>
  <mat-tab label="Highlights">
    <section class="pricing-area" id="playlists">
    <div class="section-title text-black" style="padding:10px">
    <h2>{{videosTitle}}</h2>
    </div>
    <div class="row">
      <div class="pricing-list">
        <div class="col-md-3 col-sm-10 col-xs-12" style="padding:10px" *ngFor="let item of dataSource; let i = index">
          <mat-card class="example-card">
            <mat-card-header>
              <div mat-card-avatar class="example-header-image"></div>
              <mat-card-title>{{getLinkName(item.linkName)}}</mat-card-title>
            </mat-card-header>
            <iframe width="500" height="315" [src]="getVideoHref(item.linkUrl)" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </mat-card>
        </div>
      </div>
    </div>
   <mat-paginator showFirstLastButtons [length]="dataSize" [pageSize]="10" #matPaginator1>
    </mat-paginator>

    </section>


  </mat-tab>
  <mat-tab label="Documentaries">
    <section class="pricing-area" id="playlistsCategory2">
      <div class="section-title text-black" style="padding:10px">
        <h2>{{videosTitle}}</h2>
      </div>
      <div class="row">
        <div class="pricing-list">
          <div class="col-md-3 col-sm-10 col-xs-12" style="padding:10px" *ngFor="let item of dataSourceCatTwo; let i = index">
            <mat-card class="example-card">
              <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
                <mat-card-title>{{getLinkName(item.linkName)}}</mat-card-title>
                <!--<mat-card-subtitle>Dog Breed</mat-card-subtitle>-->
              </mat-card-header>
              <iframe width="500" height="315" [src]="getVideoHref(item.linkUrl)" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </mat-card>
          </div>
        </div>
      </div>
      <mat-paginator showFirstLastButtons [length]="dataSizeCatTwo" [pageSize]="10" #matPaginator2 >
      </mat-paginator>

    </section>
  </mat-tab>
</mat-tab-group>
<app-footer></app-footer>




