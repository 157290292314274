<footer>
  <div class="footer-area bg-theme ptb--50" id="appLinks">
    <div class="container">
      <div class="footer-inner">
        <ul class="fsocial">
          <li><a href="https://apps.apple.com/app/this-is-mma/id1546766103" title="Download on the App Store"><i class="fa fa-apple"></i></a></li>
          <li><a href="https://play.google.com/store/apps/details?id=com.thisismma" title="Get it on Google Play"><i class="fa fa-android"></i></a></li>
          <li><a href="https://twitter.com/thisismma1"><i class="fa fa-twitter"></i></a></li>
          <li><a href="https://www.instagram.com/thisismmauk/"><i class="fa fa-instagram"></i></a></li>
          <li><a href="https://www.linkedin.com/in/this-is-mma-29b089217/"><i class="fa fa-linkedin"></i></a></li>
          <li><a href="https://thisismma.app/" title="This is MMA App Website" target="_blank"> <i class="fa fa-list"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
