export const newsList = [ // https://blog.feedspot.com/mma_rss_feeds/ //https://eztoolset.com/top-rss/15-top-mma-rss-feeds-in-2020/
  {
    title: 'Bellator',
    url: 'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=Bellator',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_29MlPATJ8zSr0h-hz6wQQVUao0bUq4-voyQ=s176-c-k-c0x00ffffff-no-rj-mo',
    filter: 'Bellator',
    sortDates: true,
  },
  {
    title: 'Bleacher Report MMA',
    url: 'http://bleacherreport.com/articles/feed?tag_id=24',
    image:
      'https://fm.hunter.cuny.edu/journalism/wp-content/uploads/BleacherReport_logo.jpg',
    sortDates: false,
  },
  {
    title: 'Bloody Elbow',
    url: 'http://bloodyelbow.com/rss/current',
    image:
      'https://cdn.vox-cdn.com/thumbor/av7HreXEC4HrDovY4hkIJCAWYuo=/23x0:570x365/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/26167945/a_sneak_peek_at_the_future_of_bloody_elbow_and_sb_nation___bloody_elbow.0.png',
    sortDates: false,
  },
  {
    title: 'BJPenn.com',
    url: 'https://www.bjpenn.com/feed/',
    image: 'https://www.bjpenn.com/wp-content/uploads/bjplogo.png',
    sortDates: false,
  },
  {
    title: 'Cage Warriors',
    url:
      'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=Cage+Warriors+MMA',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/MNyG2Uy8.jpg/440px-MNyG2Uy8.jpg',
    filter: 'Cage Warriors',
    sortDates: true,
  },
  {
    title: 'Combate Global',
    url:
      'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=combate+global',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_VZJgiJSFXC-m8QkHJXrjoEFTaOkdBkJwjhQ=s288-c-k-c0xffffffff-no-rj-mo',
    filter: 'Combate Global',
    sortDates: true,
  },
  {
    title: 'Evolve Daily',
    url: 'https://evolve-mma.com/blog/feed',
    image: 'https://yt3.ggpht.com/a/AGF-l79wsTIKqBtxEXJ8HfJnL5yC6avIJxQzR1Tt2g=s288-c-k-c0xffffffff-no-rj-mo',
    sortDates: false,
  },
  {
    title: 'FanSided',
    url: 'http://fansided.com/mma/feed',
    image: 'https://images2.minutemediacdn.com/image/fetch/c_fill,f_auto,w_150,h_150/https://fansided.com/wp-content/assets/site_images/fansided/logo_fansided-com__150x150.png',
    sortDates: false,
  },
  {
    title: 'Fight News Australia',
    url: 'http://www.fightnewsaustralia.com/feed',
    image:
      'https://www.fightnewsaustralia.com/wp-content/uploads/2019/12/FNA-MASTER-LOGO-2.png',
    sortDates: false,
  },
  {
    title: 'Fight Record - Muay Thai',
    url: 'https://fightrecord.co.uk/feed',
    image:
      'https://cdn.evolve-university.com/wp-content/uploads/2015/07/Classes-MuayThai.png',
    sortDates: false,
  },
  {
    title: 'Fight Sports MMA News',
    url: 'https://www.fightsports.tv/tag/mma/feed',
    image: 'https://www.fightsports.tv/wp-content/uploads/fightsports280.jpg',
    sortDates: false,
  },
  {
    title: 'Glory Kickboxing News',
    url: 'https://www.glorykickboxing.com/feed',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLR1UEN4FDB2kQifryuJETkf4aVc5bHyJ7bCfdFmlQ=s176-c-k-c0x00ffffff-no-rj-mo',
    sortDates: false,
  },
  {
    title: 'Google MMA News',
    url: 'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=MMA',
    image:
      'https://www.telegraph.co.uk/content/dam/mma/2017/08/17/TELEMMGLPICT000137551754_trans_NvBQzQNjv4BqplGOf-dgG3z4gg9owgQTXB3zF48bcOw5jNpxDOYJRn4.jpeg',
    sortDates: true,
  },
  {
    title: 'Invicta FC',
    url: 'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=Invicta+Fighting+Championship',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-Ph5B8_0ciS5x2L5pPDHkqu-r8HnE0YRldGA=s176-c-k-c0x00ffffff-no-rj-mo',
    filter: 'Invicta',
    sortDates: false,
  },
  {
    title: 'KSW',
    url:
      'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=KSW',
    image:
      'https://yt3.ggpht.com/a/AGF-l79tX_GkxmH9nhegKpTNP40r_1sF-pFmJybTuA=s288-c-k-c0xffffffff-no-rj-mo',
    filter: 'KSW',
    sortDates: true,
  },
  {
    title: 'Legacy Fighting Alliance',
    url:
      'https://news.google.com/rss/search?hl=en-GB&q=legacy+fighting+alliance&gl=GB&ceid=GB:en',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwniWLqN0wSwovfECSN8x9Mp-KYEclYHIeKQ5ZkDY=s176-c-k-c0x00ffffff-no-rj',
    filter: 'LFA',
    sortDates: true,
  },
  {
    title: 'LowKickMMA.com',
    url:
      'https://www.lowkickmma.com/feed/',
    image:
      'https://www.lowkickmma.com/wp-content/uploads/2020/04/544x180.png',
    sortDates: false,
  },
  {
    title: 'MMA Fighting',
    url: 'https://www.mmafighting.com/rss/current',
    image:
      'https://megaphone-prod.s3.amazonaws.com/podcasts/f47106d8-e5c7-11e8-afa8-a7e93a9f0370/image/uploads_2F1567104787292-h8gkhei39ld-2e9bf4839ebb1d2e2c12eb03423125a9_2FMMAFighting_Tile_3000x3000.png',
    sortDates: false,
  },
  {
    title: 'MMA Junkie',
    url: 'http://mmajunkie.com/news/rss',
    image:
      'https://pbs.twimg.com/profile_images/378800000727440831/97c2b19d95fcf45fa2a70e42cb66e113_400x400.jpeg',
    sortDates: false,
  },
  {
    title: 'MMA News',
    url: 'http://www.mmanews.com/feed',
    image:
      'https://cdn.mmanews.com/wp-content/uploads/2017/01/logo-1-300x97.png',
    sortDates: false,
  },
  {
    title: 'MMA Mania',
    url: 'http://mmamania.com/rss/current',
    image:
      'https://yt3.ggpht.com/a/AGF-l79HyyJZasm3RdcRw0zr6sGNkw2u0XIy1yUYAw=s288-c-k-c0xffffffff-no-rj-mo',
    sortDates: false,
  },
  {
    title: 'My MMA News.com',
    url: 'http://mymmanews.com/feed',
    image:
      'https://mymmanews.com/wp-content/uploads/2016/07/mymmanews_logo_Final-e1438630962528.png',
    sortDates: false,
  },
  {
    title: 'MMA NYTT',
    url: 'https://www.mmanytt.se/feed/',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLQ8EY5dP5KpGJlK_v5ldiNsmFcQONmECGqAO3-s=s176-c-k-c0x00ffffff-no-rj',
    sortDates: false,
  },
  {
    title: 'ONE Championship',
    url:
      'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=One+Championship+MMA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-avo7CHje_g3DuHWY4yChunq3X27gxB_dFGA=s176-c-k-c0x00ffffff-no-rj-mo',
    filter: 'One Championship',
    sortDates: true,
  },
  {
    title: 'Pure EVil MMA',
    url: 'https://feeds.megaphone.fm/pureevilmma',
    image: 'https://yt3.ggpht.com/Ewy-O1ZwpWDYT5NQhjLKk5pVE4y_i0YB7mTnxI3veGgKD48VDBnS57UAHyUHJnnb1ky1pEhxJg=s176-c-k-c0x00ffffff-no-rj',
    sortDates: false,
  },
  {
    title: 'Professional Fighters League',
    url:
      'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=Professional+Fighters+League',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-aJzQBTk6xBgoSMVM66zEiHs0vWOXEAlfesg=s288-c-k-c0xffffffff-no-rj-mo',
    filter: 'Professional Fighters League',
    sortDates: true,
  },
  {
    title: 'Reddit – MMA RSS Feed',
    url: 'https://www.reddit.com/r/MMA/.rss',
    image:
      'https://inews-prd-a-images.s3.eu-west-2.amazonaws.com/content/uploads/2019/04/reddit-640x360.jpg',
    sortDates: false,
  },
  {
    title: 'Round By Round Boxing',
    url: 'https://roundbyroundboxing.com/feed/',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_m3kVciI91Xy5l_1FgSJAM6_u1NWyAhBEh6g=s176-c-k-c0x00ffffff-no-rj-mo',
    sortDates: false,
  },
  {
    title: 'Sherdog MMA Articles',
    url: 'http://www.sherdog.com/rss/articles.xml',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSNhvHG5KMF-Xi6VSNwOyRIlhmtNMFpIgwNy8GPRYFiSOjc35Ry',
    sortDates: false,
  },
  {
    title: 'Sherdog MMA News',
    url: 'http://www.sherdog.com/rss/news.xml',
    image:
      'https://yt3.ggpht.com/a/AGF-l782euhpQxQhU-4wau-o7SYFlJB6alDZlOkfIA=s176-c-k-c0x00ffffff-no-rj-mo',
    sortDates: false,
  },
  /* {
    title: 'Sherdog MMA Schedule',
    url: 'https://www.sherdog.com/rss/events.xml',
    image:
      'https://yt3.ggpht.com/a/AGF-l782euhpQxQhU-4wau-o7SYFlJB6alDZlOkfIA=s176-c-k-c0x00ffffff-no-rj-mo',
    sortDates: false,
  }, */
  {
    title: 'Tiger Muay Thai & MMA',
    url: 'https://www.tigermuaythai.com/news-blog/feed',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLR2YIBMpjdj0xlAqssJNEgwqF32zccxchKF0Ve0aw=s176-c-k-c0x00ffffff-no-rj',
    sortDates: false,
  },
  {
    title: 'UFC News',
    url: 'https://news.google.com/rss/search?hl=en-GB&gl=GB&ceid=GB:en&q=UFC',
    image:
      'https://cdn4.theroar.com.au/wp-content/uploads/2016/06/ufc-logo-415x285.jpg',
    sortDates: true,
  },
  {
    title: 'UFC',
    url: 'http://www.ufc.com/rss/news',
    image:
      'https://yt3.ggpht.com/a/AGF-l78l2gK-nV9hHtUmwO2suJFV8XAp3bFcJD-kgA=s176-c-k-c0x00ffffff-no-rj-mo',
    sortDates: false,
  },
  {
    title: 'Yahoo Sports MMA',
    url: 'https://sports.yahoo.com/mma/rss',
    image:
      'https://yt3.ggpht.com/a/AATXAJytDG-3FFfcgTStBewrig2_sXB8aBH8CWjFo-Pw=s100-c-k-c0xffffffff-no-rj-mo',
    sortDates: true,
  },
];

export const videoList = [
  {
    title: 'All Access MMA',
    url:
      'https://www.youtube.com/channel/UCMPsCHWAe5S8o0rg4Wpsu5w',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLQpZym1ttmFku7xCcNz8dX4tABgQDW6aL58U3fwyw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'All Things MMA',
    url:
      'https://www.youtube.com/channel/UCYUht2ni_e-Cc1gf0cmVzlg',
    image:
      'https://yt3.ggpht.com/3orjE4f1WTBq6Dgxju2tL41ox-28-4mLnfXDweOpm0xngOAvfD-mk4dvpXAsIaYP50v8whnB9w=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Anatomy of a Fighter',
    url:
      'https://www.youtube.com/channel/UC47UlrwAYX5HRs5bm2KbEZg',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu8zcf9yn_zWS_irXW64xhl_Fqay-ud8JmvXCpi9jA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'BJJ Fanatics',
    url:
      'https://www.youtube.com/channel/UCAqme-CE-yLm01BV5nUjPPA',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSP2SJJJdunRbcqmRrGudFYtkV3wo9s5zehfvLjog=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Boxing Social',
    url:
      'https://www.youtube.com/channel/UCZEOvoFUl6pkx7rts8KKkhw',
    image:
      'https://yt3.ggpht.com/N1_cA-DCUhihu27sOVI-VrrPXV0bYNOU77njXcwS3KWJaA61BRdTa41GAWgMPWLN7JH0U2_8=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Chael Sonnan',
    url:
      'https://www.youtube.com/channel/UCRlvF4jIeBWqXJDGNXfPyVw',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu9KyR1l72Z3pNqM6hFIxED-I_3FcRH-CAbX1rBCXw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Chewjitsu',
    url:
      'https://www.youtube.com/channel/UCGCZBBvu7ZnqHYHuScODbAQ',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLTHZGK4nN4i7Jyp3BlutWghWeUfCvusnVC_Az7e=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Combat Culture',
    url:
      'https://www.youtube.com/channel/UCdCzvfhJ153Y-7ZftcfL-Tw',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwniS0mMylxyiRrzIg_HQZIRCeADB8pmqp9I1SB3faw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'ESPN MMA',
    url:
      'https://www.youtube.com/channel/UCO4AcsPKEkIqDmbeiZLfd1A',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu-KTJUj59N6qAMZanddZVdHFfFnSejcCs1Bw0DwGA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Evolve MMA',
    url:
      'https://www.youtube.com/channel/UCT7gUHH1sm5XMl_e5h7IeQQ',
    image:
      'https://yt3.ggpht.com/a/AGF-l79wsTIKqBtxEXJ8HfJnL5yC6avIJxQzR1Tt2g=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'FanSided MMA',
    url:
      'https://www.youtube.com/channel/UCKoIGUb82Ht4BntaP5KxBwA',
    image: 'https://yt3.ggpht.com/ytc/AAUvwniGEY9qQiuxxkfmcjefh9-fX3r0DxYR5hET8vy-=s176-c-k-c0x00ffffff-no-rj',
    sortDates: false,
  },
  {
    title: 'Fight Hub TV',
    url:
      'https://www.youtube.com/channel/UCwdVyruxCCqMR4DtPLhtwlg',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnheczSsxtwhX6FXBUh4Lr2CP_5hAxC_FRIfVnqQ=s176-c-k-c0x00ffffff-no-rj',
  },
  /* {
    title: 'Fighters Only',
    url:
      'https://www.youtube.com/channel/UC397nAyPEJPKr0OxcBQd_1g',
    image:
      'https://yt3.ggpht.com/a/AATXAJxMaNGlhP6IwcYuMlWClD_ix2Uw4MbiEGaWUg=s176-c-k-c0x00ffffff-no-rj-mo',
  }, */
  {
    title: 'Full Mount MMA',
    url:
      'https://www.youtube.com/channel/UCRXsUJyZKyByJixT_7mZ1Xg',
    image:
      'https://yt3.ggpht.com/a/AGF-l78_KxepQz73BSMKew6qNrwZokwaU5WAvEwgnA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Full Reptile',
    url:
      'https://www.youtube.com/channel/UCL2VR6aVxWDUY34usexCaHQ',
    image:
      'https://yt3.ggpht.com/a/AGF-l793OscmdXTT4BtYV2pmjZFv_fL35irVclZOPg=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Gracie Breakdown',
    url:
      'https://www.youtube.com/channel/UCNMZWa1QP42jHrmmzayFEeg',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLTnMp8JdsHghn3NR-Tev7StUruodmVyb1OFrtRd=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'In The Pocket MMA',
    url:
      'https://www.youtube.com/channel/UCRgBdO_9M2iqL2JnHjb3zVw',
    image:
      'https://yt3.ggpht.com/lXoxxTfVdwWibJd755orb5oIg75kdnNy2C2E3QNKPbIIvLLo9UbGO94URzDo8G6-MD-3ki_Rjw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Isso é MMA',
    url:
      'https://www.youtube.com/channel/UC1VrT8oh5pf6XRj321s7oSQ',
    image:
      'https://yt3.ggpht.com/2hzCyt0t6FMJ5k9_644UhskYl1OWJH4OWvE6a5V3Xq2Z7yvgl_oL15jBi7YAwBQmfmVxwNNmGg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Joseph Vincent',
    url:
      'https://www.youtube.com/channel/UCQ_jBtD8tlspQOOf4L1xPuw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSty-PkBn3A3lCBx2mPzsp0eB1tncS9YMabbIGfQw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'LowKick MMA',
    url:
      'https://www.youtube.com/channel/UCfDe4IsAq6iiavYnfS8TOMA',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwni82vpmIknPjH3UHOYD3i-SkoGotH7NcC-HE0A5=s176-c-k-c0x00ffffff-no-rj',
  },
{
  title: 'McLeod Scott Boxing',
    url:
  'https://www.youtube.com/channel/UCjgk-b3U4nYX5G4SlojjqRQ',
    image:
  'https://yt3.ggpht.com/ytc/AKedOLSUPLoX0ZlQmMmJsini6AFhlh3eX5TEICOgzlpi=s176-c-k-c0x00ffffff-no-rj',
},
  {
    title: 'Matchroom Boxing',
    url:
      'https://www.youtube.com/channel/UC7LReVje9aPB4B6XAsXX8WQ',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu97xBDzmkdA2bkXFWc13d05jXRVWhPKCnvcIHITmg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Michael Bisping',
    url:
      'https://www.youtube.com/channel/UCDrG2_1TcVkXKXXsD6Kjwig',
    image:
      'https://yt3.ggpht.com/UD1B4ZtTE5Ol6uwTQDe0uOd9c5aItOkfEuCcGfaGsTh-Mndr-0dxqxDId2JKEj6iNex94XA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Channel',
    url:
      'https://www.youtube.com/channel/UCuBy7MG1Fs6mFZiP7eUk0XQ',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu_blkZa7LvRGExB-dicvNJhSJtWPY2vZdqNVY6V=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Crazy',
    url:
      'https://www.youtube.com/channel/UCz1Mrrnl2IYsb8VRoSW9gEQ',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLRjKXsb4dV_GWROh-V8Z89ckBfjXpWjgskOlDXdNg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Digest',
    url:
      'https://www.youtube.com/channel/UCDQcZMuzY948G2SllT0GDvw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSAFty24fqHns4Rm2D8AI-wNuJI6Zk7n_n8XPQKsg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Fighting',
    url:
      'https://www.youtube.com/channel/UC4f1JueVgo5t9HSmobCRPug',
    image:
      'https://yt3.ggpht.com/a/AGF-l79kqItqJ1P34LHFogFOaXtwM9Z7dt1d2qiwtA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA Focus',
    url:
      'https://www.youtube.com/channel/UCcHwjDdCJdDghV1S21J33jA',
    image:
      'https://yt3.ggpht.com/a/AGF-l78D9Tlz1giCT2hSZh-oGHxSgX688itBSDEFPA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA Junkie',
    url:
      'https://www.youtube.com/channel/UCxQfUu6vIJGZDODSwhr0m9w',
    image:
      'https://yt3.ggpht.com/a/AATXAJwyZ5SJo-FfHxdGC8cOnOUuWrDlYsxbuTJEpu7EgA=s100-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA Mania',
    url:
      'https://www.youtube.com/channel/UCvVAxQ2m0fRMAR2-lOykn3w',
    image:
      'https://yt3.ggpht.com/a/AGF-l79HyyJZasm3RdcRw0zr6sGNkw2u0XIy1yUYAw=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA News',
    url:
      'https://www.youtube.com/channel/UCws1nOT4k3nrt50mueLg35Q',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnjGHJPxFm5g0II0cypYkwqL5xxzsSe0KolHtGrA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA NYTT',
    url: 'https://www.youtube.com/channel/UCJdsiy62Qqm6Z_tF78jQ1fw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLQ8EY5dP5KpGJlK_v5ldiNsmFcQONmECGqAO3-s=s176-c-k-c0x00ffffff-no-rj'
  },
  {
    title: 'MMA On The Go',
    url:
      'https://www.youtube.com/channel/UCliTk6uA8XIKTtza_SrJ0zw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLR4AjCdnrKkqnQ6MDClFaNIGNSp6uTJj1BqqI6E=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA On Point',
    url:
      'https://www.youtube.com/channel/UCbQ_WJsfwZvrSYHv6ywzyOA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_wxZ435a7k4gq4oFaj1EMU-8tLAOxDRqIDXQ=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA Release',
    url:
      'https://www.youtube.com/channel/UCShrcNYDTK6RSAcYhqcQH3A',
    image:
      'https://yt3.ggpht.com/vo_UQHP5PwD4LEIDjS3Py3I2eljuA3-M-DWMaTwHs4QCzscwqkMjR2FHlF7qJ1v299-fQwPVvQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Surge',
    url:
      'https://www.youtube.com/channel/UCk3tA3n02Znwytun9wFKb6Q',
    image:
      'https://yt3.ggpht.com/a/AGF-l79fRzZi51J935gS323Fd-8Z9rLIUJsEoKpkIg=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MMA UK',
    url:
      'https://www.youtube.com/channel/UCYxc56eD6vI4CSI8PPpIt6Q',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLR-Z0eBdbAyYZaeXUtv2WJlWFoVMrMHd_X_0svmrQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'MMA Weekly',
    url:
      'https://www.youtube.com/channel/UC7tdFNggH_jwjJTDWqYelmA',
    image:
      'https://yt3.ggpht.com/a/AGF-l78Tj6lFmw6dQNARjfsp3PGJ6rmLcBryF4m3cw=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'MotivationHub',
    url:
      'https://www.youtube.com/channel/UCR54lF_dD3f-8srmRqnTwgQ',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLRxR0LxkA0q7Iszzh9Fggi0F7avNa45DMCnpbyf=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Mulligan Brothers',
    url:
      'https://www.youtube.com/channel/UCIrn3pSjhCLp8xA7JXxi6Bw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSWN7NYapcaU_8niKMYj2d76E2JqnWOBebtKvg4SA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Muay Thai Guy',
    url:
      'https://www.youtube.com/channel/UCpRyzlMgSv7_9eMhq-l3DAw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLTW3cp241XC6xlIF9ipyt3e2HMC1N6wnzEsN1-arQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Paddy The Baddy',
    url:
      'https://www.youtube.com/channel/UC160Ln3rSb3Ym2iUxJasZVw',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSdG1NfsXm99g6Yw7Ix69RLcm5I2mjRv8vx1wQbsw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'One On One - Laura Sanko',
    url:
      'https://www.youtube.com/channel/UC2Kj_l_ZdSgwcDvwgU9E6Ng',
    image:
      'https://yt3.ggpht.com/YMT0KSyuKUZNdtPXixbdBBsorSmBPHzIwcnkN2ud0TOF6glOAI9QWM599X4Q_lBPK25Yv7tq=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Razak Sports Films',
    url:
      'https://www.youtube.com/channel/UCAoN_eyLhtnG_QPNFR7CU2A',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLQIzGbBinLP46myWITDXvmciuKELgYvm0ajTIDcBg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'RedFrost Motivation',
    url:
      'https://www.youtube.com/channel/UCXD5aOFDPO1W264sJvYc85Q',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLTjCUmCUu6ShI3aCONM-aH0HGqSZiCddphhrkpSFQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Ryan Jones Films',
    url:
      'https://www.youtube.com/channel/UCR5dLuabRJXahEY-_1_XYXQ',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLT4lBJJMs4gDrygqCV20FvdGSOrWnZE2kwGO785bQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Ring Digital',
    url:
      'https://www.youtube.com/channel/UC8ybHJltwhtpk0cFKU4G8kQ',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu--C2vZNgkmShoBSWqO-Pmj_iWTrLB8nenwAAbcTA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Schmo',
    url:
      'https://www.youtube.com/channel/UC-muTcRmDEEBTV8F8Fm82pA',
    image:
      'https://yt3.ggpht.com/a/AATXAJxDnheuz1JougJoi_3TvUokvnpTXrSQGpd2aw=s100-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Sherdog',
    url:
      'https://www.youtube.com/channel/UCW3KTagNuyADCijmSwuaNJA',
    image:
      'https://yt3.ggpht.com/a/AGF-l782euhpQxQhU-4wau-o7SYFlJB6alDZlOkfIA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Sky Sports Boxing News',
    url:
      `https://www.youtube.com/channel/UC_JQGBtA7P0RwkRxd7xpJcA`,
    image:
      'https://yt3.ggpht.com/rR2O56Z3ciAY-F99xd3UHuUjEsCoaZY0t9LkgFhK0PmUvSROPSd3oXDVoWPcwGJaXgCAz22D=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Stuart Cooper Films',
    url:
      'https://www.youtube.com/channel/UCWQj8EQOyY3HIuOq_aPbp9w',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLQLpGccw-iXA-ccUefaVSu6SNEe1f3oEf9PKezMtw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The MAC Life',
    url:
      'https://www.youtube.com/channel/UC789h3eqw0H1HqGmIsI26OA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_tZQdyIt9mryFOImc428BQQ2rytiy-YJNSQg=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'The Modern Martial Artist',
    url:
      'https://www.youtube.com/channel/UC1MHbPo9A_TcNH0fZ7ezARw',
    image:
      'https://yt3.ggpht.com/a/AGF-l79xyY25P91sy5sgh_Gpg3HSH8d-M6ZTu6wIrw=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Top Rank Boxing',
    url:
      'https://www.youtube.com/channel/UCbzRzJNHx7ZLlJML9BjZQVQ',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu_4KVNuvp2sFber86uqUq_wcpeldjOcs3RWna3rRw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'TheWeasle',
    url:
      'https://www.youtube.com/channel/UCZD2qRU8J82XGdGdUWYneNQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnipIcgvIRGmdzEoQLKK3oDyemZtakz55O3TCVyThw=s176-c-k-c0x00ffffff-no-rj',
  },
];

export const eventsList = [ // 13
  {
    title: 'Bellator',
    url:
      'https://www.youtube.com/channel/UCnkMTsKYqhHm6l6GQzg4szg',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_29MlPATJ8zSr0h-hz6wQQVUao0bUq4-voyQ=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Brave CF',
    url:
      'https://www.youtube.com/channel/UCos8j6dB44G_nlNpdJdP8SQ',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_oLpVsucJn4TRbs0-XNFOfNTDQizCkEMSi6Q=s288-c-k-c0xffffffff-no-rj-mo',
  },
 {
    title: 'Cage Glory Championship',
    url:
      'https://www.youtube.com/channel/UCiPeuv9o54EbpHU3PcDcHPg',
    image:
      'https://yt3.ggpht.com/GmQicBoa3gAWzqZpXD2kt9ZJXPk9NW0oJOEK4OZCaG8xkOIUFkaUyeL_ZbFn-upKqtOLryZT=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Cage Warriors',
    url:
      'https://www.youtube.com/channel/UCg5E4hWlXrJX7Ukx80941FA',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/MNyG2Uy8.jpg/440px-MNyG2Uy8.jpg',
  },
  {
    title: 'Combate Global',
    url:
      'https://www.youtube.com/channel/UClvfFzGFsKp5x4V8SaeG9Dw',
    image:
      'https://yt3.ggpht.com/3OJ3dBiJmSngehvMZZS25O17yHIaiBOJZgcqGVDx7h-rZTvS7LLQXm6XlLppnlw-0JgoznyL=s176-c-k-c0x00ffffff-no-rj',
  },
  {
  title: 'Eagle Fight Club',
    url:
  'https://www.youtube.com/channel/UCjObga0Gi66Wnb3seNaj81Q',
    image:
  'https://yt3.ggpht.com/OxkoVr3VSLbhRPVdauLWwnZzFE4spmMK76gn37XUGxNBDFMNh-yCWFpjP_hWTyLydxm3AVYhQ_M=s176-c-k-c0x00ffffff-no-rj',
},
  {
    title: 'EFC',
    url:
      'https://www.youtube.com/channel/UCu0pxhi-kgipKBtGeoR55hA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-Q5HIdYi2Li30rSXx0Zmj4VYKlR8BsxiwMCg=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Glory Kickboxing',
    url:
      'https://www.youtube.com/channel/UCKj5FIgxeihLRLDpVqKp_aA',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLR1UEN4FDB2kQifryuJETkf4aVc5bHyJ7bCfdFmlQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Karate Combat',
    url:
      'https://www.youtube.com/channel/UCrx0XRJeIhstGD8H60kLYYA',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu99SqspitMW0mLGFQiD3oXLrUdz163uUeCVGz4t4A=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'KSW',
    url:
      'https://www.youtube.com/channel/UCv_fwQqff6zEFDxfJh_03fg',
    image:
      'https://yt3.ggpht.com/a/AGF-l79tX_GkxmH9nhegKpTNP40r_1sF-pFmJybTuA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Invicta FC',
    url:
      'https://www.youtube.com/channel/UCL5abkoXnl_pa-QuIVIOuOA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-Ph5B8_0ciS5x2L5pPDHkqu-r8HnE0YRldGA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Legacy Fighting Alliance',
    url:
      'https://www.youtube.com/channel/UCRTCykKZ1Ah1LW7h0OpcViQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwniWLqN0wSwovfECSN8x9Mp-KYEclYHIeKQ5ZkDY=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'M1 Global',
    url:
      'https://www.youtube.com/channel/UCcJXxoW4w0dDKu8aZA9uqUw',
    image:
      'https://yt3.ggpht.com/a/AGF-l7_fEDT26y6xo93rTTOgRmglZb99TwA4y4GPrA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Professional Fighters League',
    url:
      'https://www.youtube.com/channel/UCPrONRG9hO1f-OrxW-WH-xg',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-aJzQBTk6xBgoSMVM66zEiHs0vWOXEAlfesg=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'ONE Championship',
    url:
      'https://www.youtube.com/channel/UCiormkBf3jm6mfb7k0yPbKA',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-avo7CHje_g3DuHWY4yChunq3X27gxB_dFGA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Rizin Fighting Federation',
    url:
      'https://www.youtube.com/channel/UCZZ0UGjWsRdM8_5bsqtxYaQ',
    image:
      'https://yt3.ggpht.com/a/AGF-l796cMXLy6yZk1JAZ8x8VUKfbTM4j9osvN9EQw=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'UFC',
    url:
      'https://www.youtube.com/channel/UCvgfXK4nTYKudb0rFR6noLA',
    image:
      'https://yt3.ggpht.com/a/AGF-l78l2gK-nV9hHtUmwO2suJFV8XAp3bFcJD-kgA=s288-c-k-c0xffffffff-no-rj-mo',
  },
];

export const podcastList = [
  // https://mmasucka.com/2020/03/26/what-mma-podcasts-you-should-be-listening-to/
  // https://www.youtube.com/watch?v=tu5KR9kIrqk
  {
    title: 'Anik & Florian Podcast',
    url:
      'https://www.youtube.com/channel/UCDqSRXkx0E58VdH__Y8expQ',
    image:
      'https://yt3.ggpht.com/a/AGF-l7-KFNkVvU8WfXYqsJtCALQBIRAryNVZJPTLHA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Believe You Me',
    url:
      'https://www.youtube.com/channel/UCMTmbsnNJ8zj7E0UZfsoS7A',
    image:
      'https://yt3.ggpht.com/a/AGF-l7822JszSqaNUoR7UtatyNX9rGT5IHrW1Kt_7g=s288-c-k-c0xffffffff-no-rj-mo',
    filter: 'Believe You Me',
  },
  {
    title: 'Below The Belt',
    url:
      'https://www.youtube.com/channel/UCnmeuCU-pPyx920dkJjHdDw',
    image:
      'https://yt3.ggpht.com/a/AGF-l79PB_XhSwcuI834eK-J4mMrMIDkmHDtV_Y0NA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Bloody Elbow',
    url:
      'https://www.youtube.com/channel/UCFoX81d3fNHrcUckyQdRzLQ',
    image:
      'https://cdn.vox-cdn.com/thumbor/av7HreXEC4HrDovY4hkIJCAWYuo=/23x0:570x365/1310x873/cdn.vox-cdn.com/uploads/chorus_image/image/26167945/a_sneak_peek_at_the_future_of_bloody_elbow_and_sb_nation___bloody_elbow.0.png',
  },
  {
    title: 'The Brit Pack MMA',
    url:
      'https://www.youtube.com/channel/UCSONYw3QrvLCL8jl6HGPX_w',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwngao0I7mT8orjy34RmQ8EIk74kJ-WKFshX9Jpph=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Church of MMA',
    url:
      'https://www.youtube.com/channel/UCf3hIknAkUbIYeySBjcXnEQ',
    image:
      'https://yt3.ggpht.com/a/AATXAJzTzWbWif2kbQwOiJR6umr2gfrL9Jp84WiwgA=s100-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Combat Challenge',
    url:
      'https://www.youtube.com/channel/UChMUBgBgn4S8NEJ-0ufT_Fg',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwngD_ohJh-XLrBmgRDrCcaJonJCBTV_OYdJMJfni=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Chewjitsu',
    url:
      'https://www.youtube.com/channel/UCTSmtpFVLcZGS7G599_vCow',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnjxIU15TWhK0MZsQLSQFyqShwQeCQDlajVAP-kl=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'THE FIGHT with Teddy Atlas',
    url: 'https://www.youtube.com/channel/UC7D_b_1q4Dxz9ebeEYVVxWQ',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu-XfQ0t1zNguhaQ4dqLl2Ur2Kn0JjLbqE5AQ75xgw=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Fighter and The Kid',
    url:
      'https://www.youtube.com/channel/UC6AbsTfBMQ_dHjtipwh3bZg',
    image:
      'https://yt3.ggpht.com/a/AGF-l79hmnQnmtnkJkk5g2qI2xtg2RvK1z-AWWbAWA=s288-c-k-c0xffffffff-no-rj-mo',
  },
  {
    title: 'Fight Disciples',
    url:
      'https://www.youtube.com/channel/UCNfSq-sz-GfOM9xM3d-upiA',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSTwXF5CS5QQh3pDUCLODHQZdckMjuzhZcTtefe=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Higher Level MMA',
    url:
      'https://www.youtube.com/channel/UCOlkpOsj9Juw8Psw_q5DoKg',
    image:
      'https://yt3.ggpht.com/a/AATXAJw1bqUPw4_9dEtUBXrS_LvrKrYkYCIzdNxvTQ=s100-c-k-c0xffffffff-no-rj-mo',
    filter: 'PODCAST',
  },
  {
    title: 'Karyn Bryant',
    url:
      'https://www.youtube.com/channel/UCb4ynPVEfqFAmQPBYUoopJw',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnh2q1wdX6BTuiriCweBZnMZUMjknsymZQDWpCGe=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Martial Arts With Jake',
    url:
      'https://www.youtube.com/channel/UCuj3q1Kujw9rmTIVDWRnCeQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnhM_z5QuKxuWAEfnJCNrjggCitZb2cUMHMq2XV0=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The MMA Hour with Ariel Helwani',
    url:
      'UC4f1JueVgo5t9HSmobCRPug',
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWLeWjyuLBMpSiSlar4FDgKSSu9bJ9K4emvQ&usqp=CAU',
    useApi: true,
    filter: 'MMA Hour',
  },
  {
    title: 'MMANUTS MMA Podcast',
    url:
      'https://www.youtube.com/channel/UCbHXoBQmfaPDkAAlgZpYbvA',
    image:
      'https://yt3.ggpht.com/ytc/AMLnZu--77FhtoTp2u6ascMEXaMqWWXLYRpb15R0EjJw_w=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Morning Kombat',
    url:
      'https://www.youtube.com/channel/UC9Qy3sHrr5wil-rkYcmcNcw',
    image:
      'https://yt3.ggpht.com/YX4XkgvgJYD8CZ11zjbyG4hvRxqLTFabUZY04FkbG2_FFXN5SloqHyeBfJAzyOVtWjN3HnkcfQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Pure EVil MMA',
      url:
    'https://www.youtube.com/channel/UCCIbE0X-qkAr4Bfdzxf0EpQ',
      image:
    'https://yt3.ggpht.com/Ewy-O1ZwpWDYT5NQhjLKk5pVE4y_i0YB7mTnxI3veGgKD48VDBnS57UAHyUHJnnb1ky1pEhxJg=s176-c-k-c0x00ffffff-no-rj'
  },
  {
    title: 'Prospect MMA Podcast',
    url:
      'https://www.youtube.com/channel/UCPgrssPNXgpylCCT33W74iA',
    image:
      'https://yt3.ggpht.com/ytc/AKedOLSk8Ix_pJt5WPWt86gAWBTun6MgP6jRIr-nIS2j0A=s176-c-k-c0x00ffffff-no-rj',
    sortDates: true,
  },
  {
    title: 'Real Quick With Mike Swick',
    url:
      'https://www.youtube.com/channel/UCxY3IbiquwT3QjrgIgg86KQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnifsQ51nDRsEu7gijjcfCOp_enkJeM-xkSvIRiapQ=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Severe MMA',
    url:
      'https://www.youtube.com/channel/UCpPJZXMWn-MYc1jNwzI-rSw',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwngQQYkEyZjm3QeywFs45V3kkp1RRNKyChbnQsMV=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'Submission Radio',
    url:
      'https://www.youtube.com/channel/UCNrEHIf8QmKK-cAag4YxIXQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnjML7b36fP1eWDrvjBV6QXPREa57MDSPH97YdZA=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Strenuous Life Podcast',
    url: 'https://www.youtube.com/channel/UCxFCWZJbnlRyhw3vmDx0Rlg',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwnhUwR9_mY7779NjkpnmjWW2hAmu9hzg1xIXXNDD=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'The Weighing In Podcast',
    url: 'https://www.youtube.com/channel/UC2eEKIAE77w0xYWjvyWP7MQ',
    image:
      'https://yt3.ggpht.com/ytc/AAUvwniehtyEhuNCkqZBgDZ2xJ7-YkzMnDFSWOOm7GbaGg=s176-c-k-c0x00ffffff-no-rj',
  },
  {
    title: 'UFC Unfiltered',
    url:
      'UCvgfXK4nTYKudb0rFR6noLA',
    image:
      'https://yt3.ggpht.com/a/AATXAJyxGW85J5iTBnpuDd3oNAnrsDaWO7FgCZc5pw=s100-c-k-c0xffffffff-no-rj-mo',
    filter: 'Unfiltered',
    useApi: true,
  },
];
